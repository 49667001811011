import React from "react";

import { PageHeader } from "../../../../components/PageHeader";
import { ProfileBody } from "../../../../components/ProfileBody";
import { Helmet } from "react-helmet";
import { showTitle } from "lib/helpers/other";
import moment from "moment";

import "./style.scss";

export const AddNewAgent = () => {

  return (
    <div className="add-new-agent profile-page-container">
      <Helmet>
          <title>
            {showTitle("New Agent")}
          </title>
      </Helmet>
      <PageHeader title="New Agent" backURL="/admin/roles" backTitle="Roles" />
      <div className="tab-container">
        <div className="tab-container__column">
          <div className="avatar-wrapper">
            <h2 className="heading heading-2">New Agent</h2>
            <h6 className="heading heading-caption-bold">From {moment(new Date).format("ll")}</h6>
          </div>
        </div>
        <ProfileBody profileType="new-agent" />
      </div>
    </div>
  );
};
