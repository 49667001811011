import React, { useEffect } from "react";

const LiveChatScript = () => {
  useEffect(() => {
    // Initialize the LiveChat widget
    window.__lc = window.__lc || {};
    window.__lc.license = 13162764;

    const script = document.createElement("script");
    script.async = true;
    script.type = "text/javascript";
    script.src = "https://cdn.livechatinc.com/tracking.js";
    document.head.appendChild(script);

    // Cleanup: Remove script on unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <noscript>
      <a href="https://www.livechat.com/chat-with/13162764/" rel="nofollow">
        Chat with us
      </a>
      , powered by{" "}
      <a
        href="https://www.livechat.com/?welcome"
        rel="noopener nofollow noreferrer"
        target="_blank"
      >
        LiveChat
      </a>
    </noscript>
  );
};

export default LiveChatScript;
