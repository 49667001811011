import { useEffect } from "react";

const FrontChatScript = () => {
  useEffect(() => {
    // Add the chatbot script
    const script = document.createElement("script");
    script.id = "chatbot-script";
    script.src = "https://chat-assets.frontapp.com/v1/chat.bundle.js";
    script.async = true;
    script.onload = () => {
      // Initialize the chatbot after the script loads
      window.FrontChat("init", {
        chatId: "9b07d667e90ef09b7f31354e0063c283",
        useDefaultLauncher: true,
      });
    };
    document.body.appendChild(script);

    return () => {
      // Cleanup: remove the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI element required
};

export default FrontChatScript;
