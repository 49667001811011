import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAlert } from "react-alert";
import { useRoutes } from "./routes";
import { useAuth } from "./hooks/auth.hook";
import { AuthContext } from "./context/AuthContext";
import { useCookies } from "hooks/useCookies";
import { handleLogOut } from "./components/TopNavigation/helpers";
import "./scss/main.scss";
import { Cookies } from "components/Cookies";
import { useDispatch, useSelector } from "react-redux";
import { fetchTenantSettings } from "redux/actions/app";
import { Helmet } from "react-helmet";
import { showTitle } from "lib/helpers/other";
import FrontChatScript from "chatbots/FrontChatScript.js";
import LiveChatScript from "chatbots/LiveChatScript.js";

function App() {
  const { cookiesAccepted, handleAcceptCookies } = useCookies();
  const alert = useAlert();
  const { token, login, logout, userId, role, firstLogin } = useAuth();
  const dispatch = useDispatch();
  const tenantSettings = useSelector((state) => state.app.tenantSettings);

  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated, role, firstLogin);

  useEffect(() => {
    dispatch(fetchTenantSettings());
  }, [dispatch]);

  const root = document.documentElement;

  useEffect(() => {
    if (tenantSettings) {
      const { primary_color, secondary_color } = tenantSettings;

      if (primary_color) {
        root?.style.setProperty("--customWhite01", secondary_color);
      }
      if (secondary_color) {
        root?.style.setProperty("--customBlue", primary_color);
      }
    }
  }, [tenantSettings, root]);

  window.onoffline = () => {
    alert.error(
      "Your network connection is lost. Please check your network and reload the page.",
      {
        timeout: 3000,
      }
    );
  };

  window.ononline = () => {
    alert.show("You are back online. Please restart the page.");
  };

  function setExpirationTimer() {
    const expirationDate = new Date(new Date().getTime()).toISOString();
    const storageExpiration =
      JSON.parse(localStorage.getItem("userData")) &&
      JSON.parse(localStorage.getItem("userData")).expirationTime;
    if (expirationDate >= storageExpiration) {
      handleLogOut(role);
    }
  }

    setExpirationTimer();

  document.addEventListener("click", () => {
    let expirationDate
    if (role === "admin" || role === "agent") { 
      expirationDate = new Date(new Date().getTime() + 1000 * 60 * 10);
    } else {
      expirationDate = new Date(new Date().getTime() + 1000 * 60 * 5);
    }
    const data = JSON.parse(localStorage.getItem("userData"));
    if (!data) {
      return;
    }
    data.expirationTime = expirationDate.toISOString();
    localStorage.setItem(
      "userData",
      JSON.stringify({
        ...data,
      })
    );
    clearInterval(timer);
    timer = setInterval(setExpirationTimer, 1000 * 60 * 2);
  });
  let timer = setInterval(setExpirationTimer, 1000 * 60 * 2);
  const { favicon } = tenantSettings || {};

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        logout,
        userId,
        isAuthenticated,
        role,
        firstLogin,
      }}
    >
      {favicon && (
        <Helmet>
          <link rel="icon" href={favicon} />
        </Helmet>
      )}
        <Helmet>
          <title>
            {showTitle(`Online Debt Repayment Portal`)}
          </title>
        </Helmet>
      <Router>
        {tenantSettings && <div className="app">
          {routes}
          {/* loading chatbot scripts */}
          {tenantSettings?.company_short_name === "Investinet" && <FrontChatScript/>}
          {tenantSettings?.company_short_name === "Consuegra" && <LiveChatScript />}
        </div>}
        {!cookiesAccepted ? (
          <Cookies handleAcceptCookies={handleAcceptCookies} />
        ) : null}
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
