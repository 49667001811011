import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import "./style.scss";
import {
  OtherMasterCardIcon,
  OtherVisaIcon,
} from "../../../../components/icons/OtherIcons";
import { isAdmin } from "../../../../lib/api/admin";
import { showTitle } from "lib/helpers/other";

export const PaymentMethod = ({
  handleSelectPaymentMethod,
  savedCards,
  savedBankAccounts,
  setSelectedPaymentCard,
  setSelectedPaymentAccount,
  isChangingPaymentMethod,
}) => {
  const sum = localStorage.getItem("paymentSum");
  const [selectedMethod, setSelectedMethod] = useState("account");
  const [selectedCard, setSelectedCard] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("new");

  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const handleSelectMethod = (method) => {
    if (method === "account" && !isChangingPaymentMethod) {
      setSelectedCard("");
      setSelectedPaymentCard("");
    }

    setSelectedMethod(method);
    handleSelectPaymentMethod(method);
  };

  const handleSelectCard = (id) => {
    setSelectedCard(id);
    setSelectedPaymentCard(id);
  };

  const handleSelectAccount = (account) => {
    if (account === "new") {
      setSelectedAccount("new");
    } else {
      setSelectedAccount(account.Id);
    }

    setSelectedPaymentAccount(account);
  };

  const isAdminAgent = () => {
    return isAdmin() === "admin" || isAdmin() === "agent";
  };

  const isShowingPhysicalPymentsButton = () => {
    return (
      (savedCards &&
      !savedCards.length &&
      savedBankAccounts &&
      !savedBankAccounts.length ||
      profileInfo?.new_payment_system) &&
      !sum
    );
  };

  const isShowingSavedCards = () => {
    return (
      savedCards.length > 0 &&
      selectedMethod === "card"
    );
  };

  const isShowingSavedBankAccounts = () => {
    return (
      savedBankAccounts.length > 0 &&
      selectedMethod === "account"
    );
  };

  return (
    <div className="payment-method">
      <Helmet>
        <title>
          {showTitle("Chose Payment Method")}
        </title>
      </Helmet>
      <h2 className="heading heading-2">
        {isChangingPaymentMethod ? "Change Payment Method" : "Payment Method"}
      </h2>
      <p className="heading heading-body-medium">I want to pay using my:</p>
      <button
        onClick={() => handleSelectMethod("account")}
        className={
          selectedMethod === "account"
            ? "button button-transparent selected"
            : "button button-transparent"
        }
      >
        Bank Account
      </button>
      <button
        onClick={() => handleSelectMethod("card")}
        className={
          selectedMethod === "card"
            ? "button button-transparent selected"
            : "button button-transparent"
        }
      >
        Debit Card
      </button>
      {/* Do not show this button if account has any saved payment method */}
      {isShowingPhysicalPymentsButton() && isAdminAgent() && (
        <button
          onClick={() => handleSelectMethod("physical")}
          className={
            selectedMethod === "physical"
              ? "button button-transparent selected"
              : "button button-transparent"
          }
        >
          Mail/Wire
        </button>
      )}

      {isShowingSavedBankAccounts() && (
        <div className="saved-cards">
          <h3 className="heading heading-3">Saved Bank Accounts</h3>
          <div className="cards-wrapper">
            {savedBankAccounts &&
              savedBankAccounts.map((account, i) => (
                <button
                  key={i}
                  className={
                    selectedAccount === account.Id
                      ? "saved-card saved-card--account active"
                      : "saved-card saved-card--account"
                  }
                  onClick={() => handleSelectAccount(account)}
                >
                  <span className="heading heading-body-bold">ACH</span>
                  <h4 className="heading heading-body-bold">
                    {account.BankName}
                  </h4>
                  <div className="saved-card__footer">
                    {account.AccountNumber.substr(- 4)}
                  </div>
                </button>
              ))}
            <button
              className={
                selectedAccount === "new"
                  ? "saved-card active saved-card--new-card"
                  : "saved-card saved-card--new-card"
              }
              onClick={() => handleSelectAccount("new")}
            >
              <h4 className="heading heading-body-bold">
                Pay with New Account
              </h4>
            </button>
          </div>
        </div>
      )}
      {isShowingSavedCards() && (
        <div className="saved-cards">
          <h3 className="heading heading-3">Saved Cards</h3>
          <div className="cards-wrapper">
            {savedCards &&
              savedCards.map((card, i) => (
                <button
                  key={i}
                  className={
                    selectedCard === card.Id
                      ? "saved-card active"
                      : "saved-card"
                  }
                  onClick={() => handleSelectCard(card.Id)}
                >
                  {card.CardName.slice(0, -4).trim() === "VISA" ? (
                    <OtherVisaIcon />
                  ) : (
                    <OtherMasterCardIcon />
                  )}

                  <h4 className="heading heading-body-bold">
                    {card.Description}
                  </h4>
                  <div className="saved-card__footer">
                    <div>
                      &#8226;&#8226;&#8226;&#8226; {card.CardName.slice(-4)}
                    </div>
                    <div>
                      {card.Expiry.Month}/{card.Expiry.Year.toString().slice(2)}
                    </div>
                  </div>
                </button>
              ))}
            <button
              className={
                selectedCard === "new"
                  ? "saved-card active saved-card--new-card"
                  : "saved-card saved-card--new-card"
              }
              onClick={() => handleSelectCard("new")}
            >
              <h4 className="heading heading-body-bold">Pay from a New Card</h4>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

PaymentMethod.propTypes = {
  handleSelectPaymentMethod: PropTypes.func,
  savedCards: PropTypes.array,
  savedBankAccounts: PropTypes.array,
  setSelectedPaymentCard: PropTypes.func,
  setSelectedPaymentAccount: PropTypes.func,
  isChangingPaymentMethod: PropTypes.bool,
};
