import React, { useEffect } from "react";
import { Error404 } from "lib/helpers/dataLayers";
import { CustomButton } from "components/ui-kit/CustomButtom";

import "./style.scss";

export const Page404 = () => {

  useEffect(() => {
    Error404();
  }, []);

  const goToLogin = () => {
    window.location.replace("/");
  }

  return (
    <div className="no-page-container">
      <div className="message">404</div>
      <CustomButton
        text="Go To Login"
        onClickHandler={() => goToLogin()}
      />
    </div>
  )
}