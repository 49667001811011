import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import "./style.scss";
import { SuccessTitle } from "components/SuccessTitle";

export const SPPSuccessModal = ({
  modal,
  parentModal,
  parentToggle,
  asaFlow,
}) => {
  const { t } = useTranslation();

  const closeModal = () => {
    window.location.replace("/");
  };

  const renderSubtitle = () => {
    return (
      <div className="subtitle">
        {asaFlow
          ? t(
              "Please note, your offer has not been reviewed by an attorney and may not be accepted. You will receive a written notice when your agreement has been reviewed."
            )
          : t(
              "Thank you for setting up a monthly payment plan. You will receive your plan details via email."
            )}
      </div>
    );
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  };

  return (
    <Modal
      isOpen={modal}
      onOpened={closeParentModal}
      className="spp-success-modal"
      id="final-details"
      centered
    >
      <ModalBody>
        <div className="mod-header">
          <button className="close-modal-btn" onClick={() => closeModal()}>
            <Close />
          </button>
        </div>
        <div className="mod-body">
          <div className="title">
            <SuccessTitle />
          </div>
          {renderSubtitle()}
          <div className="mod-footer">
            <div className="download-receipt-btn" onClick={() => closeModal()}>
              <span id="see-plan-details">{t("See Plan Details")}</span>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

SPPSuccessModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
};
