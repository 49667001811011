import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { CustomButton } from "components/ui-kit/CustomButtom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useAlert } from "react-alert";
import { AuthAgreementPhoneMultiPay } from "pages/SetupPlan/components/BankAccountPayment/AuthAgreementPhoneMultiPay";
import {
  OtherMasterCardIcon,
  OtherVisaIcon,
} from "components/icons/OtherIcons";
import amexSvg from "scss/images/amex.svg";
import discoverSVG from "scss/images/discover.svg";
import paypalIcon from "scss/images/paypal.png";

import { LoadingSpinner } from "components/LoadingSpinner";
import { getPossiblePaymentSchedule } from "lib/api/consumer";

import { NewDebitCardModal } from "../NewDebitCardModal";
import { NewBankAccountModal } from "../NewBankAccountModal";
import { NewPayPalModal } from "../NewPayPalModal";

import { FinalDetailsModal } from "../FinalDetailsModal";

import { submitPayment, setDefaultPaymentMethod } from "lib/api/common";
import { ChangePaymentSuccess } from "ConsumerPage/PaymentsHistoryContainer/ExtraPaymentFlow/SelectPaymentMethodModal/ChangePaymentSuccess";
import { NACHAPhoneMultiModalData } from "lib/common/NACHAModalData";
import { showDependOnTenant } from "multitenancy/multitenancyPayPal";
import { ReceiptModal } from "components/ReceiptModal";
import "./style.scss";

import { getBlockingDatesFunc } from "lib/helpers/other";

export const PaymentDetailsModal = ({
  isModalOpen,
  toggle,
  selectedPlan,
  debt,
  isChangePaymentMethod,
  parentModal,
  parentToggle,
}) => {
  const { id } = useParams();

  const alert = useAlert();

  const tomorrow = moment().add(1, "days");

  const setMaxDate = () => {
    return moment().add(1, "month");
  };

  const [changePaymentSuccessModal, setChangePaymentSuccessModal] =
    useState(false);
  const toggleChangePaymentSuccessModal = () =>
    setChangePaymentSuccessModal(!changePaymentSuccessModal);
  const [isShowingModal, setIsShowingModal] = useState(false);
  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number || ""
  );
  const hours = useSelector((state) => state.app.tenantSettings?.hours || "");
  const companyName = useSelector(
    (state) => state.app.tenantSettings?.tenant?.full_name || ""
  );
  const [selectedPaymentType, setPaymentType] = useState("");
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const [selectedPaymentObject, setSelectedPaymentObject] = useState({});
  const [confirmBtnActive, setConfirmBtnActive] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [monthDay, setMonthDay] = useState(null);
  const [selectedDateValue, setSelectedDateValue] = useState(null);
  const [possiblePaymentSchedule, setPossiblePaymentSchedule] = useState(null);
  const [innerLoading, setLoading] = useState(false);
  const [blockingDates, setBlockingDates] = useState([]);

  const [isFinalDetailsModalOpen, setIsFinalDetailsModalOpen] = useState(false);
  const toggleFinalDetailsModal = () =>
    setIsFinalDetailsModalOpen(!isFinalDetailsModalOpen);

  const [addNewBankAccountModal, setAddNewBankAccountModal] = useState(false);
  const toggleAddNewBankAccountModal = () =>
    setAddNewBankAccountModal(!addNewBankAccountModal);
  const [addNewDebitCardModal, setAddNewDebitCardModal] = useState(false);
  const toggleAddNewDebitCardModal = () =>
    setAddNewDebitCardModal(!addNewDebitCardModal);
  const [addNewPayPalModal, setAddNewPayPalModal] = useState(false);
  const toggleAddNewPayPalModal = () =>
    setAddNewPayPalModal(!addNewPayPalModal);

  const savedPayPalAccounts = useSelector(
    (state) => state.consumerSavedPaymentMethod.savedPayPalAccounts
  );
  const savedDebitCards = useSelector(
    (state) => state.consumerSavedPaymentMethod.savedDebitCards
  );
  const savedBankAccount = useSelector(
    (state) => state.consumerSavedPaymentMethod.savedBankAccount
  );
  const loading = useSelector(
    (state) => state.consumerSavedPaymentMethod.loading
  );
  const profileInfo = useSelector((state) => state.profile.profileData.profile);
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const consumersData = useSelector((state) => state.profile.profileData);

  useEffect(() => {
    if (isModalOpen) {
      getBlockingDatesFunc(
        setBlockingDates,
        setInitialDate,
        id,
        isChangePaymentMethod,
        monthDay
      );
    }
  }, [isModalOpen]);

  useEffect(() => {
    calculatePlan(3);
  }, [monthDay]);

  const setInitialDate = () => {
    if (selectedPlan.payment_frequency === "Monthly") {
      handleChangeDate(tomorrow._d);
    } else {
      handleChangeDate(tomorrow._d);
    }
  };

  const handleChangePaymentType = (e, item) => {
    setPaymentType(e.target.id);
    setIsPaymentSelected(true);
    if (dateValue) {
      setConfirmBtnActive(true);
    }
    if (isChangePaymentMethod) {
      setConfirmBtnActive(true);
    }
    setSelectedPaymentObject(item);
  };

  const renderCardIcon = (cardType, paymentProc) => {
    if (paymentProc === "PayPal") {
      return <img className="card-icon" src={paypalIcon} alt="amex" />;
    }

    cardType = cardType.slice(0, -5);
    if (cardType === "MASTERCARD") {
      return <OtherMasterCardIcon />;
    }
    if (cardType === "VISA") {
      return <OtherVisaIcon />;
    }
    if (cardType === "AMEX") {
      return <img className="card-icon" src={amexSvg} alt="amex" />;
    }
    if (cardType === "DISCOVER") {
      return <img className="card-icon" src={discoverSVG} alt="discover" />;
    }
  };

  const renderCardNumber = (cardNumber) => {
    cardNumber = cardNumber.slice(-4);
    return <span className="card-number">•••• {cardNumber}</span>;
  };

  const renderCardTime = (expiry) => {
    const month = expiry.Month;
    const year = expiry.Year.toString().substr(-2);
    return (
      <span className="card-time">
        {month}/{year}
      </span>
    );
  };

  const renderAccountNumber = (accountNumber) => {
    accountNumber = accountNumber.slice(-4);
    return (
      <span className="card-number">
        •••• {accountNumber}
        <span className="card-time"> Account</span>
      </span>
    );
  };

  const renderBankName = (bankName) => {
    return <span className="card-time">{bankName}</span>;
  };

  const handleChangeDate = (value) => {
    setDateValue(value);
    setMonthDay(+moment(value).format("DD"));

    if (isPaymentSelected) {
      setConfirmBtnActive(true);
    }
  };

  const calculatePlan = async (count) => {
    if (monthDay) {
      setLoading(true);
      const data = await getPossiblePaymentSchedule({
        client_id: id,
        plan_id: selectedPlan.id,
        start_day: monthDay,
        page: 1,
        per_page: count,
      });
      if (data.success === "True") {
        setLoading(false);
        setPossiblePaymentSchedule(data);
        setSelectedDateValue(monthDay);
      }
    }
  };

  const submitPaymentMailWire = async () => {
    const request = {
      client_id: id,
      payment_method: "not_provided",
      plan_id: selectedPlan.id,
      debt_id: debt.debt_id,
      start_day: selectedDateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
      agreement_timestamp: new Date(),
    };
    setLoading(true);
    let data = await submitPayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const submitPaymentForAccountNumber = async () => {
    const request = {
      client_id: id,
      account_number: selectedPaymentObject.AccountNumber,
      account_type: "1",
      agreement_timestamp: new Date(),
      bank_name: selectedPaymentObject.BankName,
      card_id: selectedPaymentObject.Id,
      debt_id: debt.debt_id,
      // email: profileInfo.email,
      name_on_check: profileInfo.full_name,
      payment_method: "ach",
      routing_number: selectedPaymentObject.RoutingNumber,
      plan_id: selectedPlan.id,
      start_day: selectedDateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
    };

    setLoading(true);
    let data = await submitPayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const submitPaymentForDebitCard = async () => {
    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      card_id: selectedPaymentObject?.Id,
      debt_id: debt.debt_id,
      payment_method: "card",
      plan_id: selectedPlan.id,
      start_day: selectedDateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
    };

    setLoading(true);
    let data = await submitPayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const submitPaymentForPayPal = async () => {
    const request = {
      client_id: id,
      agreement_timestamp: new Date(),
      card_id: selectedPaymentObject?.Id,
      debt_id: debt.debt_id,
      payment_method: "paypal",
      plan_id: selectedPlan.id,
      start_day: selectedDateValue,
      start_date: possiblePaymentSchedule?.possible_payment_info.start_date,
      end_date: possiblePaymentSchedule?.possible_payment_info.end_date,
    };

    setLoading(true);
    let data = await submitPayment(request);

    if (data.success === "True") {
      setLoading(false);
      toggleFinalDetailsModal();
    } else {
      setLoading(false);
      alert.error(data.message);
    }
  };

  const openNewBankAccountModal = () => {
    toggleAddNewBankAccountModal();
  };

  const openNewDebitCardModal = () => {
    toggleAddNewDebitCardModal();
  };

  const openNewPayPalModal = () => {
    toggleAddNewPayPalModal();
  };

  const changeExistingPaymentMethod = async () => {
    let request;
    if (selectedPaymentType === "mailWire") {
      request = {
        client_id: id,
        card_id: "None",
        data_id: debt.referenceNumber,
      };
    } else {
      request = {
        client_id: id,
        card_id: selectedPaymentObject.Id,
        data_id: debt.referenceNumber,
      };
    }

    setLoading(true);
    setIsPaymentSelected(false);
    const setDefaultPaymentMethodResponse = await setDefaultPaymentMethod(
      request
    );

    if (setDefaultPaymentMethodResponse.success !== "True") {
      setLoading(false);
      setIsPaymentSelected(true);
      alert.error(setDefaultPaymentMethodResponse.message);
    } else {
      setLoading(false);
      setIsPaymentSelected(true);
      toggleChangePaymentSuccessModal();
    }
  };

  const handleGoNext = () => {
    if (
      isChangePaymentMethod &&
      selectedPaymentType !== "newDebitCard" &&
      selectedPaymentType !== "newBankAccount" &&
      selectedPaymentType !== "newPayPal"
    ) {
      // change payment method request
      changeExistingPaymentMethod();
      return;
    }

    if (selectedPaymentType === "mailWire") {
      submitPaymentMailWire();
      return;
    }

    if (selectedPaymentType === "newDebitCard") {
      openNewDebitCardModal();
    } else if (selectedPaymentType === "newBankAccount") {
      openNewBankAccountModal();
    } else if (selectedPaymentType === "newPayPal") {
      openNewPayPalModal();
    } else {
      if (selectedPaymentObject?.AccountNumber) {
        submitPaymentForAccountNumber();
      } else if (selectedPaymentObject.CardName) {
        submitPaymentForDebitCard();
      } else if (selectedPaymentObject.Email) {
        submitPaymentForPayPal();
      }
    }
  };

  const nextBtnText = () => {
    if (
      selectedPaymentType === "newDebitCard" ||
      selectedPaymentType === "newBankAccount" ||
      selectedPaymentType === "newPayPal"
    ) {
      return "Next";
    } else {
      return "Confirm Payment Details";
    }
  };
  const showNacha = () => {
    const excludedTypes = [
      "newDebitCard",
      "newBankAccount",
      "newPayPal",
      "mailWire",
    ];
    return selectedPaymentType && !excludedTypes.includes(selectedPaymentType);
  };

  const closeAndReboot = () => {
    setMonthDay(null);

    toggle();
  };

  const closeParentModal = async () => {
    await setPaymentType("");
    await setIsPaymentSelected(false);
    await setConfirmBtnActive(false);
    if (parentModal) {
      parentToggle();
    }
  };

  const checkCountPaymMethod = () => {
    const count = savedBankAccount?.length + savedDebitCards?.length;
    if (count > 0) {
      return true;
    } else {
      return false;
    }
  };
  const tenantName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const isJavitch = tenantName === "JB LLC";
  const isLitigation = debt?.suit_filed && !debt?.judgment_date;
  const showLitigation = isJavitch && isLitigation;

  const renderNACHABlock = () => {
    return (
      <div className="nacha-con">
        <AuthAgreementPhoneMultiPay
          accountType={selectedPaymentObject?.TypeOfAccount ? "Checking" : null}
          accountNumber={selectedPaymentObject?.AccountNumber || null}
          consumerName={
            accountPaymentRequest.name_on_check ||
            consumersData.profile?.full_name
          }
          selectedPaymentAccount={"new"}
          setIsShowingModal={setIsShowingModal}
          isChangingPaymentMethod={isChangePaymentMethod}
          startDate={possiblePaymentSchedule?.possible_payment_info.start_date}
          endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
          consumerState={consumersData.profile?.state}
          numberOfPayments={
            possiblePaymentSchedule?.possible_payment_info?.payments_number
          }
          payment_frequency={selectedPlan?.payment_frequency}
          start_amount={
            possiblePaymentSchedule?.possible_payment_info.start_amount
          }
          end_amount={possiblePaymentSchedule?.possible_payment_info.end_amount}
          debt={debt}
        />
      </div>
    );
  };

  const renderReceiptModal = () => {
    return (
      <ReceiptModal
        data={NACHAPhoneMultiModalData(
          companyName,
          phone,
          hours,
          possiblePaymentSchedule?.possible_payment_info.start_date,
          possiblePaymentSchedule?.possible_payment_info.end_date,
          accountPaymentRequest.name_on_check ||
            consumersData.profile?.full_name,
          selectedPaymentObject?.TypeOfAccount ? "Checking" : null,
          selectedPaymentObject?.AccountNumber || null,
          isChangePaymentMethod,
          consumersData.profile?.state,
          possiblePaymentSchedule?.possible_payment_info.payments_number,
          selectedPlan?.payment_frequency,
          possiblePaymentSchedule?.possible_payment_info.start_amount,
          possiblePaymentSchedule?.possible_payment_info.end_amount,
          null,
          showLitigation
        )}
        setShowModal={setIsShowingModal}
      />
    );
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onOpened={closeParentModal}
        // toggle={toggle}
        scrollable={true}
        className="payment-derails-modal"
      >
        {loading || innerLoading ? <LoadingSpinner middleFixed={true} /> : null}
        <ModalBody>
          <div className="mod-header">
            <span>Payment Details</span>
            <button
              className="close-modal-btn"
              onClick={() => closeAndReboot()}
            >
              <Close />
            </button>
          </div>
          <div className="mod-body">
            {!isChangePaymentMethod && (
              <div className="date-picker-container">
                <div className="title">Set a Payment Day</div>
                <div className="date-picker-border">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      InputProps={{ readOnly: true }}
                      variant="inline"
                      format="MM/dd/yyyy"
                      id="birthDate"
                      onChange={handleChangeDate}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        id: "paymentDateClicked",
                      }}
                      name="test"
                      value={dateValue}
                      minDate={tomorrow}
                      shouldDisableDate={(dateParam) => {
                        return (
                          blockingDates.indexOf(
                            moment(dateParam).format("YYYY-MM-DD")
                          ) !== -1
                        );
                      }}
                      maxDate={setMaxDate()}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
            )}
            <div className="payment-method-block">
              <div className="title">Payment Method</div>
              {savedPayPalAccounts?.map((item, i) => (
                <div
                  key={i}
                  className={`payment-method-container ${
                    selectedPaymentType === item.Id ? "active" : "inactive"
                  }`}
                >
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id={item.Id}
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e, item)}
                    />
                    <div className="label">
                      <div>
                        {renderCardIcon(item.Email, "PayPal")}
                        <span className="card-number">{item.Email}</span>
                      </div>
                      <span className="card-time">{item.CustomerName}</span>
                    </div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {savedDebitCards?.map((item, i) => (
                <div
                  key={i}
                  className={`payment-method-container ${
                    selectedPaymentType === item.Id ? "active" : "inactive"
                  }`}
                >
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id={item.Id}
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e, item)}
                    />
                    <div className="label">
                      <div>
                        {renderCardIcon(item.CardName)}
                        {renderCardNumber(item.CardName)}
                      </div>
                      {renderCardTime(item.Expiry)}
                    </div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {savedBankAccount?.map((item, i) => (
                <div
                  key={i}
                  className={`payment-method-container ${
                    selectedPaymentType === item.Id ? "active" : "inactive"
                  }`}
                >
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id={item.Id}
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e, item)}
                    />
                    <div className="label">
                      <div>{renderAccountNumber(item.AccountNumber)}</div>
                      {renderBankName(item.BankName)}
                    </div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              ))}
              {checkCountPaymMethod() && (
                <div className="or-block-container">
                  <hr></hr>
                  <span>or add new</span>
                  <hr></hr>
                </div>
              )}
              {showDependOnTenant(profileInfo) && (
                <div
                  className={`payment-method-container ${
                    selectedPaymentType === "newPayPal" ? "active" : "inactive"
                  }`}
                >
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id="newPayPal"
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e)}
                    />
                    <div className="label">PayPal</div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
              <div
                className={`payment-method-container ${
                  selectedPaymentType === "newDebitCard" ? "active" : "inactive"
                }`}
              >
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="newDebitCard"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">Debit Card</div>
                  <span className="checkmark"></span>
                </label>
              </div>
              <div
                className={`payment-method-container ${
                  selectedPaymentType === "newBankAccount"
                    ? "active"
                    : "inactive"
                }`}
              >
                <label className="verification-radio">
                  <input
                    type="radio"
                    id="newBankAccount"
                    name="verificationRadio"
                    onClick={(e) => handleChangePaymentType(e)}
                  />
                  <div className="label">Bank Account</div>
                  <span className="checkmark"></span>
                </label>
              </div>
              {profileInfo?.new_payment_system && (
                <div
                  className={`payment-method-container ${
                    selectedPaymentType === "mailWire" ? "active" : "inactive"
                  }`}
                >
                  <label className="verification-radio">
                    <input
                      type="radio"
                      id="mailWire"
                      name="verificationRadio"
                      onClick={(e) => handleChangePaymentType(e)}
                    />
                    <div className="label">Mail/Wire</div>
                    <span className="checkmark"></span>
                  </label>
                </div>
              )}
            </div>
            {showNacha() && renderNACHABlock()}
            {isShowingModal && renderReceiptModal()}
          </div>
          <div className="mod-footer">
            <CustomButton
              text="Cancel"
              onClickHandler={() => closeAndReboot()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={nextBtnText()}
              onClickHandler={() => handleGoNext()}
              disabled={!confirmBtnActive || innerLoading}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
      </Modal>
      {isChangePaymentMethod && (
        <ChangePaymentSuccess
          modal={changePaymentSuccessModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
        />
      )}
      {isFinalDetailsModalOpen && (
        <FinalDetailsModal
          isModalOpen={isFinalDetailsModalOpen}
          parentModal={isModalOpen}
          parentToggle={toggle}
          startDate={possiblePaymentSchedule?.possible_payment_info.start_date}
          endDate={possiblePaymentSchedule?.possible_payment_info.end_date}
          selectedPlan={selectedPlan}
          selectedPaymentObject={selectedPaymentObject}
        />
      )}
      {selectedPaymentType === "newDebitCard" && addNewDebitCardModal && (
        <NewDebitCardModal
          modal={addNewDebitCardModal}
          toggle={toggleAddNewDebitCardModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          dateValue={selectedDateValue}
          possiblePaymentSchedule={possiblePaymentSchedule}
          selectedPlan={selectedPlan}
          type="PLAN"
          isChangePaymentMethod={isChangePaymentMethod}
        />
      )}
      {selectedPaymentType === "newPayPal" && (
        <NewPayPalModal
          modal={addNewPayPalModal}
          toggle={toggleAddNewPayPalModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          dateValue={selectedDateValue}
          possiblePaymentSchedule={possiblePaymentSchedule}
          selectedPlan={selectedPlan}
          type="PLAN"
          isChangePaymentMethod={isChangePaymentMethod}
        />
      )}
      {selectedPaymentType === "newBankAccount" && (
        <NewBankAccountModal
          modal={addNewBankAccountModal}
          toggle={toggleAddNewBankAccountModal}
          parentModal={isModalOpen}
          parentToggle={toggle}
          debt={debt}
          dateValue={selectedDateValue}
          possiblePaymentSchedule={possiblePaymentSchedule}
          selectedPlan={selectedPlan}
          type="PLAN"
          isChangePaymentMethod={isChangePaymentMethod}
        />
      )}
    </>
  );
};
