import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useAlert } from "react-alert";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import { Close } from "@material-ui/icons";

import {
  fetchSavedBankAccounts,
  fetchSavedDebitCards,
} from "redux/actions/consumerSavedPaymentMethod";
import { fetchDefaultPaymentMethod } from "redux/actions/getDefaultPaymentMethod";

import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { setRequest } from "../../../../redux/actions/setupPlan";
import { addBankAccount } from "../../../../lib/api/consumer";
import { fetchConsumerProfile } from "../../../../redux/actions/profile";
import { CustomButton } from "../../../../components/ui-kit/CustomButtom";

import { useTranslation } from "react-i18next";

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

export const AddBankAccountModal = ({ modal, toggle, selectedDataId }) => {
  const { t } = useTranslation();

  const alert = useAlert();
  const dispatch = useDispatch();
  const [accountType, setType] = useState("1");
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [authMethod, setAuthMethod] = useState("web");

  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const isNextButtonDisabled = useSelector(
    (state) => state.setupPlan.isNextButtonDisabled
  );
  const consumersData = useSelector((state) => state.profile.profileData);

  const singleBalance = useSelector((state) => state.singleBalance.balanceData);

  useEffect(() => {
    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        name_on_check: consumersData.profile?.full_name,
        email: consumersData.profile?.email,
      })
    );
    // eslint-disable-next-line
  }, [consumersData]);

  const closeModal = () => {
    dispatch(fetchSavedBankAccounts(id, selectedDataId));
    dispatch(fetchSavedDebitCards(id, selectedDataId));
    dispatch(fetchDefaultPaymentMethod(id, selectedDataId));

    toggle();
  };

  const handleAddNewACH = async () => {
    if (!accountPaymentRequest.authorization) {
      accountPaymentRequest.authorization = "web";
    }

    setLoading(true);
    setIsConfirmDisabled(true);

    let requestInfo;
    if (id) {
      requestInfo = { ...accountPaymentRequest, client_id: id };
    } else {
      requestInfo = { ...accountPaymentRequest };
    }

    requestInfo.bank_name = accountPaymentRequest.bank_name?.trim();

    const accountData = await addBankAccount({
      ...requestInfo,
      data_id: selectedDataId,
    });

    if (accountData.success === "True") {
      closeModal();
    } else {
      setLoading(false);
      setIsConfirmDisabled(false);
      wtError(accountData);
      alert.error(accountData.message);
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const handleSetAccountPaymentRequest = (e) => {
    e.persist();
    const value =
      e.target.name === "bank_name" ? e.target.value : e.target.value.trim();

    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: singleBalance?.debts_info[0].id,
        account_type: accountType,
        institution_number: 803,
        [e.target.name]: value,
      })
    );
  };

  const setAuthMethodFunc = (e) => {
    setAuthMethod(e.target.value);
    handleSetAccountPaymentRequest(e);
  };

  const backBtn = () => {
    toggle();
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} className="add-payment-modal">
        <ModalBody>
          <div className="mod-header">
            <span>{t("Add Bank Account")}</span>
            <button className="close-modal-btn" onClick={() => toggle()}>
              <Close />
            </button>
          </div>
          <form className="mod-body">
            {id && (
              <div className="form-group">
                <label
                  className="heading heading-caption-bold"
                  htmlFor="account_type"
                >
                  Authorization Method
                </label>
                <Select
                  labelId="authorization"
                  id="authorization"
                  name="authorization"
                  onChange={setAuthMethodFunc}
                  input={
                    <InputBase
                      value={authMethod}
                      className="input input-medium"
                    />
                  }
                >
                  <MenuItem value={"web"}>Web</MenuItem>
                  <MenuItem value={"verbal"}>Verbal</MenuItem>
                  <MenuItem value={"written"}>Written</MenuItem>
                </Select>
              </div>
            )}
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="account_type"
              >
                {t("Account Type")}
              </label>
              <Select
                labelId="type"
                id="account_type"
                name="account_type"
                onChange={handleTypeChange}
                input={
                  <InputBase
                    value={accountType}
                    className="input input-medium"
                  />
                }
              >
                <MenuItem value={"1"}>{t("Checking")}</MenuItem>
                <MenuItem value={"2"}>{t("Savings")}</MenuItem>
              </Select>
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="name_on_check"
              >
                {t("Name On Check")}
              </label>
              <input
                type="text"
                className="input input-medium"
                name="name_on_check"
                id="name_on_check"
                onChange={handleSetAccountPaymentRequest}
                defaultValue={accountPaymentRequest.name_on_check}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="routing_number"
              >
                {t("Routing Number")}
              </label>
              <input
                type="number"
                className="input input-medium"
                id="routing_number"
                name="routing_number"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.routing_number}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="account_number"
              >
                {t("Account Number")}
              </label>
              <input
                type="number"
                className="input input-medium"
                name="account_number"
                id="account_number"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.account_number}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="bank_name"
              >
                {t("Bank Name")}
              </label>
              <input
                type="text"
                className="input input-medium"
                id="bank_name"
                name="bank_name"
                onChange={handleSetAccountPaymentRequest}
                value={accountPaymentRequest.bank_name}
              />
            </div>
            <div className="form-group">
              <label
                className="heading heading-caption-bold"
                htmlFor="bank_name"
              >
                {t("Email")}
              </label>
              <input
                type="text"
                className="input input-medium"
                id="email"
                name="email"
                defaultValue={accountPaymentRequest.email}
                onChange={handleSetAccountPaymentRequest}
              />
            </div>
          </form>
          <div className="mod-footer">
            <CustomButton
              text={t("Cancel")}
              onClickHandler={() => backBtn()}
              optionalClass="cancel-btn"
            />
            <CustomButton
              text={t("Add Bank Account")}
              onClickHandler={() => handleAddNewACH()}
              disabled={isNextButtonDisabled || isConfirmDisabled}
              optionalClass="confirm-btn"
            />
          </div>
        </ModalBody>
        {loading ? <LoadingSpinner middleFixed={true} /> : null}
      </Modal>
    </>
  );
};

AddBankAccountModal.propTypes = {
  toggle: PropTypes.func,
  modal: PropTypes.bool,
};
