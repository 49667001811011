import React, {useState} from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useAlert } from "react-alert";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { LoadingSpinner } from "components/LoadingSpinner";
import { deletePaymentPlan } from "lib/api/common";

import "./style.scss";

export const DeletePlanModal = ({toggle, isModalOpen, selectedPlan, fetchConsumerPossiblePlan}) => {
  const alert = useAlert();
  const profileData = useSelector((state) => state.profile.profileData);
  const [loading, setLoading] = useState(false);

  const handleDeletePlan = async() => {
    try {
      setLoading(true);
      const response = await deletePaymentPlan(selectedPlan.id);
      if (response?.success === true) {
        await fetchConsumerPossiblePlan();
        toggle();
      } else {
        alert.error(response.message);
      }
    } catch (error) {
      alert.error("Failed to delete Plan");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggle}
      centered
      className="delete-plan-modal"
    >
      <ModalBody>
        {loading && (<LoadingSpinner middleFixed={true} />)}
        <button className="close-modal-btn" onClick={() => toggle()}>
          <Close />
        </button>
        <div className="mod-body">
          <div className="title">Delete Payment Plan</div>
          <div className="subtitle">Are you sure you want to delete this payment plan for {profileData?.profile?.full_name}?</div>
        </div>
        <div className="mod-footer">
          <CustomButton
            text="Back"
            onClickHandler={() => toggle()}
            optionalClass="cancel-btn"
          />
          <CustomButton
            text="Delete Plan"
            onClickHandler={handleDeletePlan}
            optionalClass="delete-btn"
          />
        </div>
      </ModalBody>
    </Modal>
  )
}