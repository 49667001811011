import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import { Switch } from "@material-ui/core";
import { useAlert } from "react-alert";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";

import { LoadingSpinner } from "components/LoadingSpinner";
import { timePeriods } from "./timePeriodRange";
import { timezonePeriods } from "./timePeriodRange";
import {
  getContactPreference,
  putContactPreference,
  sendEsingLetter,
} from "lib/api/common";

import { CheckIcon } from "scss/multitenantIcons/checkIcon";
import whiteCheck from "scss/images/white-check.png";
import planeIcon from "scss/images/plane-icon.png";

import { CustomButton } from "components/ui-kit/CustomButtom";
import { fetchConsumerProfile } from "redux/actions/profile";

import {
  formattingPhoneNumber,
  unformattedPhoneNumber,
} from "lib/helpers/formatters";
import { validateEmail } from "lib/helpers/validations";

import "./style.scss";

export const ContactPreferenceModal = ({ modal, toggle }) => {
  const alert = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const tenantName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const isTromberg = tenantName === "TMP";
  const profileInfo = useSelector((state) => state.profile.profileData.profile);

  const fetchProfileData = () => {
    dispatch(fetchConsumerProfile(id));
  };

  const sendEsignLetter = async () => {
    if (!profileInfo?.esign) {
      // send esign letter and when success fetch profile
      setLoading(true);
      const data = await sendEsingLetter({ client_id: id });
      if (data.success === "True") {
        await fetchProfileData();
        setLoading(false);
        alert.success("E-sign Authorization sent");
      } else {
        alert.error(data.message);
        setLoading(false);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [isMailValid, setIsMailValid] = useState(true);

  const [selectedTimezone, setSelectedTimezone] = useState("");

  const [phoneCallToggleState, setPhoneCallToggleState] = useState(false);
  const [phoneCallSameEveryDay, setPhoneCallSameEveryDay] = useState(false);
  const [phoneCallSameFrom, setPhoneCallSameFrom] = useState("8:00 AM");
  const [phoneCallSameTo, setPhoneCallSameTo] = useState("9:00 PM");
  const [phoneCallMonFrom, setPhoneCallMonFrom] = useState("8:00 AM");
  const [phoneCallMonTo, setPhoneCallMonTo] = useState("9:00 PM");
  const [phoneCallTueFrom, setPhoneCallTueFrom] = useState("8:00 AM");
  const [phoneCallTueTo, setPhoneCallTueTo] = useState("9:00 PM");
  const [phoneCallWedFrom, setPhoneCallWedFrom] = useState("8:00 AM");
  const [phoneCallWedTo, setPhoneCallWedTo] = useState("9:00 PM");
  const [phoneCallThuFrom, setPhoneCallThuFrom] = useState("8:00 AM");
  const [phoneCallThuTo, setPhoneCallThuTo] = useState("9:00 PM");
  const [phoneCallFriFrom, setPhoneCallFriFrom] = useState("8:00 AM");
  const [phoneCallFriTo, setPhoneCallFriTo] = useState("9:00 PM");
  const [phoneDays, setPhoneDays] = useState([]);
  const [userPhone, setUserPhone] = useState(null);
  const [userEmail, setUserEmail] = useState();

  const [textToggleState, setTextToggleState] = useState(false);
  const [textSameEveryDay, setTextSameEveryDay] = useState(false);
  const [textSameFrom, setTextSameFrom] = useState("8:00 AM");
  const [textSameTo, setTextSameTo] = useState("9:00 PM");
  const [textMonFrom, setTextMonFrom] = useState("8:00 AM");
  const [textMonTo, setTextMonTo] = useState("9:00 PM");
  const [textTueFrom, setTextTueFrom] = useState("8:00 AM");
  const [textTueTo, setTextTueTo] = useState("9:00 PM");
  const [textWedFrom, setTextWedFrom] = useState("8:00 AM");
  const [textWedTo, setTextWedTo] = useState("9:00 PM");
  const [textThuFrom, setTextThuFrom] = useState("8:00 AM");
  const [textThuTo, setTextThuTo] = useState("9:00 PM");
  const [textFriFrom, setTextFriFrom] = useState("8:00 AM");
  const [textFriTo, setTextFriTo] = useState("9:00 PM");
  const [textDays, setTextDays] = useState([]);

  const [emailToggleState, setEmailToggleState] = useState(false);
  const [emailSameEveryDay, setEmailSameEveryDay] = useState(false);
  const [emailSameFrom, setEmailSameFrom] = useState("8:00 AM");
  const [emailSameTo, setEmailSameTo] = useState("9:00 PM");
  const [emailMonFrom, setEmailMonFrom] = useState("8:00 AM");
  const [emailMonTo, setEmailMonTo] = useState("9:00 PM");
  const [emailTueFrom, setEmailTueFrom] = useState("8:00 AM");
  const [emailTueTo, setEmailTueTo] = useState("9:00 PM");
  const [emailWedFrom, setEmailWedFrom] = useState("8:00 AM");
  const [emailWedTo, setEmailWedTo] = useState("9:00 PM");
  const [emailThuFrom, setEmailThuFrom] = useState("8:00 AM");
  const [emailThuTo, setEmailThuTo] = useState("9:00 PM");
  const [emailFriFrom, setEmailFriFrom] = useState("8:00 AM");
  const [emailFriTo, setEmailFriTo] = useState("9:00 PM");
  const [emailDays, setEmailDays] = useState([]);

  const weekDaysMapper = ["Mon", "Tue", "Wed", "Thu", "Fri"];

  useEffect(() => {
    const fetchInfo = async () => {
      setLoading(true);
      const data = await getContactPreference(id);
      if (data.success === "True") {
        await setFetchState(data.data);
        setLoading(false);
      } else {
        alert.error(data.message);
        setLoading(false);
      }
    };

    fetchInfo();
  }, []);

  const setFetchState = (data) => {
    setSelectedTimezone(data.timezone);
    setUserEmail(data.contact_email_address);
    setIsMailValid(validateEmail(data.contact_email_address));
    setUserPhone(data.contact_phone_number);

    // set initial state for phone call
    setPhoneCallToggleState(data.phone);
    setPhoneDays(data.phone_days);
    setPhoneCallSameEveryDay(data.phone_details.same_everyday);

    if (data.phone_details.same_everyday_from !== "") {
      setPhoneCallSameFrom(data.phone_details.same_everyday_from);
    }
    if (data.phone_details.same_everyday_to !== "") {
      setPhoneCallSameTo(data.phone_details.same_everyday_to);
    }
    if (data.phone_details.mon_from !== "") {
      setPhoneCallMonFrom(data.phone_details.mon_from);
    }
    if (data.phone_details.mon_to !== "") {
      setPhoneCallMonTo(data.phone_details.mon_to);
    }
    if (data.phone_details.tue_from !== "") {
      setPhoneCallTueFrom(data.phone_details.tue_from);
    }
    if (data.phone_details.tue_to !== "") {
      setPhoneCallTueTo(data.phone_details.tue_to);
    }
    if (data.phone_details.wed_from !== "") {
      setPhoneCallWedFrom(data.phone_details.wed_from);
    }
    if (data.phone_details.wed_to !== "") {
      setPhoneCallWedTo(data.phone_details.wed_to);
    }
    if (data.phone_details.thu_from !== "") {
      setPhoneCallThuFrom(data.phone_details.thu_from);
    }
    if (data.phone_details.thu_to !== "") {
      setPhoneCallThuTo(data.phone_details.thu_to);
    }
    if (data.phone_details.fri_from !== "") {
      setPhoneCallFriFrom(data.phone_details.fri_from);
    }
    if (data.phone_details.fri_to) {
      setPhoneCallFriTo(data.phone_details.fri_to);
    }

    // set initial state for text
    setTextToggleState(data.text);
    setTextDays(data.text_days);
    setTextSameEveryDay(data.text_details.same_everyday);

    if (data.text_details.same_everyday_from !== "") {
      setTextSameFrom(data.text_details.same_everyday_from);
    }
    if (data.text_details.same_everyday_to !== "") {
      setTextSameTo(data.text_details.same_everyday_to);
    }
    if (data.text_details.mon_from !== "") {
      setTextMonFrom(data.text_details.mon_from);
    }
    if (data.text_details.mon_to !== "") {
      setTextMonTo(data.text_details.mon_to);
    }
    if (data.text_details.tue_from !== "") {
      setTextTueFrom(data.text_details.tue_from);
    }
    if (data.text_details.tue_to !== "") {
      setTextTueTo(data.text_details.tue_to);
    }
    if (data.text_details.wed_from !== "") {
      setTextWedFrom(data.text_details.wed_from);
    }
    if (data.text_details.wed_to !== "") {
      setTextWedTo(data.text_details.wed_to);
    }
    if (data.text_details.thu_from !== "") {
      setTextThuFrom(data.text_details.thu_from);
    }
    if (data.text_details.thu_to !== "") {
      setTextThuTo(data.text_details.thu_to);
    }
    if (data.text_details.fri_from !== "") {
      setTextFriFrom(data.text_details.fri_from);
    }
    if (data.text_details.fri_to !== "") {
      setTextFriTo(data.text_details.fri_to);
    }

    // set initial state for email
    setEmailToggleState(data.email);
    setEmailDays(data.email_days);
    setEmailSameEveryDay(data.email_details.same_everyday);

    if (data.email_details.same_everyday_from !== "") {
      setEmailSameFrom(data.email_details.same_everyday_from);
    }
    if (data.email_details.same_everyday_to !== "") {
      setEmailSameTo(data.email_details.same_everyday_to);
    }
    if (data.email_details.mon_from !== "") {
      setEmailMonFrom(data.email_details.mon_from);
    }
    if (data.email_details.mon_to !== "") {
      setEmailMonTo(data.email_details.mon_to);
    }
    if (data.email_details.tue_from !== "") {
      setEmailTueFrom(data.email_details.tue_from);
    }
    if (data.email_details.tue_to !== "") {
      setEmailTueTo(data.email_details.tue_to);
    }
    if (data.email_details.wed_from !== "") {
      setEmailWedFrom(data.email_details.wed_from);
    }
    if (data.email_details.wed_to !== "") {
      setEmailWedTo(data.email_details.wed_to);
    }
    if (data.email_details.thu_from !== "") {
      setEmailThuFrom(data.email_details.thu_from);
    }
    if (data.email_details.thu_to !== "") {
      setEmailThuTo(data.email_details.thu_to);
    }
    if (data.email_details.fri_from !== "") {
      setEmailFriFrom(data.email_details.fri_from);
    }
    if (data.email_details.fri_to !== "") {
      setEmailFriTo(data.email_details.fri_to);
    }
  };

  useEffect(() => {
    if (phoneDays.length === 5) {
      setPhoneCallSameEveryDay(true);
    } else if (phoneDays.length === 0) {
      setPhoneCallToggleState(false);
    } else {
      setPhoneCallSameEveryDay(false);
    }
    if (textDays.length === 5) {
      setTextSameEveryDay(true);
    } else if (textDays.length === 0) {
      setTextToggleState(false);
    } else {
      setTextSameEveryDay(false);
    }
    if (emailDays.length === 5) {
      setEmailSameEveryDay(true);
    } else if (emailDays.length === 0) {
      setEmailToggleState(false);
    } else {
      setEmailSameEveryDay(false);
    }
  }, [phoneDays, textDays, emailDays]);

  useEffect(() => {
    if (phoneCallSameEveryDay) {
      setPhoneDays(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    }
    if (textSameEveryDay) {
      setTextDays(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    }
    if (emailSameEveryDay) {
      setEmailDays(["Mon", "Tue", "Wed", "Thu", "Fri"]);
    }
  }, [phoneCallSameEveryDay, textSameEveryDay, emailSameEveryDay]);

  const sendContactPreference = async () => {
    let phoneCallSameFromReq = "";
    let phoneCallSameToReq = "";
    let phoneCallMonFromReq = "";
    let phoneCallMonToReq = "";
    let phoneCallTueFromReq = "";
    let phoneCallTueToReq = "";
    let phoneCallWedFromReq = "";
    let phoneCallWedToReq = "";
    let phoneCallThuFromReq = "";
    let phoneCallThuToReq = "";
    let phoneCallFriFromReq = "";
    let phoneCallFriToReq = "";

    let textSameFromReq = "";
    let textSameToReq = "";
    let textMonFromReq = "";
    let textMonToReq = "";
    let textTueFromReq = "";
    let textTueToReq = "";
    let textWedFromReq = "";
    let textWedToReq = "";
    let textThuFromReq = "";
    let textThuToReq = "";
    let textFriFromReq = "";
    let textFriToReq = "";

    let emailSameFromReq = "";
    let emailSameToReq = "";
    let emailMonFromReq = "";
    let emailMonToReq = "";
    let emailTueFromReq = "";
    let emailTueToReq = "";
    let emailWedFromReq = "";
    let emailWedToReq = "";
    let emailThuFromReq = "";
    let emailThuToReq = "";
    let emailFriFromReq = "";
    let emailFriToReq = "";

    phoneDays.map((day) => {
      if (day === "Mon") {
        phoneCallMonFromReq = phoneCallMonFrom;
        phoneCallMonToReq = phoneCallMonTo;
      }
      if (day === "Tue") {
        phoneCallTueFromReq = phoneCallTueFrom;
        phoneCallTueToReq = phoneCallTueTo;
      }
      if (day === "Wed") {
        phoneCallWedFromReq = phoneCallWedFrom;
        phoneCallWedToReq = phoneCallWedTo;
      }
      if (day === "Thu") {
        phoneCallThuFromReq = phoneCallThuFrom;
        phoneCallThuToReq = phoneCallThuTo;
      }
      if (day === "Fri") {
        phoneCallFriFromReq = phoneCallFriFrom;
        phoneCallFriToReq = phoneCallFriTo;
      }
    });

    if (phoneCallSameEveryDay) {
      phoneCallSameFromReq = phoneCallSameFrom;
      phoneCallSameToReq = phoneCallSameTo;

      phoneCallMonFromReq = "";
      phoneCallMonToReq = "";
      phoneCallTueFromReq = "";
      phoneCallTueToReq = "";
      phoneCallWedFromReq = "";
      phoneCallWedToReq = "";
      phoneCallThuFromReq = "";
      phoneCallThuToReq = "";
      phoneCallFriFromReq = "";
      phoneCallFriToReq = "";
    }

    textDays.map((day) => {
      if (day === "Mon") {
        textMonFromReq = textMonFrom;
        textMonToReq = textMonTo;
      }
      if (day === "Tue") {
        textTueFromReq = textTueFrom;
        textTueToReq = textTueTo;
      }
      if (day === "Wed") {
        textWedFromReq = textWedFrom;
        textWedToReq = textWedTo;
      }
      if (day === "Thu") {
        textThuFromReq = textThuFrom;
        textThuToReq = textThuTo;
      }
      if (day === "Fri") {
        textFriFromReq = textFriFrom;
        textFriToReq = textFriTo;
      }
    });

    if (textSameEveryDay) {
      textSameFromReq = textSameFrom;
      textSameToReq = textSameTo;

      textMonFromReq = "";
      textMonToReq = "";
      textTueFromReq = "";
      textTueToReq = "";
      textWedFromReq = "";
      textWedToReq = "";
      textThuFromReq = "";
      textThuToReq = "";
      textFriFromReq = "";
      textFriToReq = "";
    }

    emailDays.map((day) => {
      if (day === "Mon") {
        emailMonFromReq = emailMonFrom;
        emailMonToReq = emailMonTo;
      }
      if (day === "Tue") {
        emailTueFromReq = emailTueFrom;
        emailTueToReq = emailTueTo;
      }
      if (day === "Wed") {
        emailWedFromReq = emailWedFrom;
        emailWedToReq = emailWedTo;
      }
      if (day === "Thu") {
        emailThuFromReq = emailThuFrom;
        emailThuToReq = emailThuTo;
      }
      if (day === "Fri") {
        emailFriFromReq = emailFriFrom;
        emailFriToReq = emailFriTo;
      }
    });

    if (emailSameEveryDay) {
      emailSameFromReq = emailSameFrom;
      emailSameToReq = emailSameTo;

      emailMonFromReq = "";
      emailMonToReq = "";
      emailTueFromReq = "";
      emailTueToReq = "";
      emailWedFromReq = "";
      emailWedToReq = "";
      emailThuFromReq = "";
      emailThuToReq = "";
      emailFriFromReq = "";
      emailFriToReq = "";
    }

    const request = {
      timezone: selectedTimezone,
      phone: phoneCallToggleState,
      text: textToggleState,
      email: emailToggleState,
      contact_email_address: userEmail,
      contact_phone_number: unformattedPhoneNumber(userPhone),
      phone_details: {
        same_everyday: phoneCallSameEveryDay,
        same_everyday_from: phoneCallSameFromReq,
        same_everyday_to: phoneCallSameToReq,
        mon_from: phoneCallMonFromReq,
        mon_to: phoneCallMonToReq,
        tue_from: phoneCallTueFromReq,
        tue_to: phoneCallTueToReq,
        wed_from: phoneCallWedFromReq,
        wed_to: phoneCallWedToReq,
        thu_from: phoneCallThuFromReq,
        thu_to: phoneCallThuToReq,
        fri_from: phoneCallFriFromReq,
        fri_to: phoneCallFriToReq,
        contact_type: "phone",
      },
      text_details: {
        same_everyday: textSameEveryDay,
        same_everyday_from: textSameFromReq,
        same_everyday_to: textSameToReq,
        mon_from: textMonFromReq,
        mon_to: textMonToReq,
        tue_from: textTueFromReq,
        tue_to: textTueToReq,
        wed_from: textWedFromReq,
        wed_to: textWedToReq,
        thu_from: textThuFromReq,
        thu_to: textThuToReq,
        fri_from: textFriFromReq,
        fri_to: textFriToReq,
        contact_type: "text",
      },
      email_details: {
        same_everyday: emailSameEveryDay,
        same_everyday_from: emailSameFromReq,
        same_everyday_to: emailSameToReq,
        mon_from: emailMonFromReq,
        mon_to: emailMonToReq,
        tue_from: emailTueFromReq,
        tue_to: emailTueToReq,
        wed_from: emailWedFromReq,
        wed_to: emailWedToReq,
        thu_from: emailThuFromReq,
        thu_to: emailThuToReq,
        fri_from: emailFriFromReq,
        fri_to: emailFriToReq,
        contact_type: "email",
      },
    };

    setLoading(true);
    const data = await putContactPreference(request, id);
    if (data.success === "True") {
      await fetchProfileData();
      setLoading(false);
      toggle();
      isTromberg && window.location.reload();
    } else {
      alert.error(data.message);
      setLoading(false);
    }
  };

  const selectTimeZone = (value) => {
    setSelectedTimezone(value.target.value);
  };

  const toggleByType = (e) => {
    if (e.target.name === "phone") {
      setPhoneCallToggleState(e.target.checked);
    }
    if (e.target.name === "text") {
      setTextToggleState(e.target.checked);
    }
    if (e.target.name === "email") {
      setEmailToggleState(e.target.checked);
    }
  };

  const phoneTimeChange = (value, period, day) => {
    if (day === "same") {
      if (period === "from") {
        setPhoneCallSameFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallSameTo(value.target.value);
      }
    }
    if (day === "Mon") {
      if (period === "from") {
        setPhoneCallMonFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallMonTo(value.target.value);
      }
    }
    if (day === "Tue") {
      if (period === "from") {
        setPhoneCallTueFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallTueTo(value.target.value);
      }
    }
    if (day === "Wed") {
      if (period === "from") {
        setPhoneCallWedFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallWedTo(value.target.value);
      }
    }
    if (day === "Thu") {
      if (period === "from") {
        setPhoneCallThuFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallThuTo(value.target.value);
      }
    }
    if (day === "Fri") {
      if (period === "from") {
        setPhoneCallFriFrom(value.target.value);
      }
      if (period === "to") {
        setPhoneCallFriTo(value.target.value);
      }
    }
  };

  const textTimeChange = (value, period, day) => {
    if (day === "same") {
      if (period === "from") {
        setTextSameFrom(value.target.value);
      }
      if (period === "to") {
        setTextSameTo(value.target.value);
      }
    }
    if (day === "Mon") {
      if (period === "from") {
        setTextMonFrom(value.target.value);
      }
      if (period === "to") {
        setTextMonTo(value.target.value);
      }
    }
    if (day === "Tue") {
      if (period === "from") {
        setTextTueFrom(value.target.value);
      }
      if (period === "to") {
        setTextTueTo(value.target.value);
      }
    }
    if (day === "Wed") {
      if (period === "from") {
        setTextWedFrom(value.target.value);
      }
      if (period === "to") {
        setTextWedTo(value.target.value);
      }
    }
    if (day === "Thu") {
      if (period === "from") {
        setTextThuFrom(value.target.value);
      }
      if (period === "to") {
        setTextThuTo(value.target.value);
      }
    }
    if (day === "Fri") {
      if (period === "from") {
        setTextFriFrom(value.target.value);
      }
      if (period === "to") {
        setTextFriTo(value.target.value);
      }
    }
  };

  const emailTimeChange = (value, period, day) => {
    if (day === "same") {
      if (period === "from") {
        setEmailSameFrom(value.target.value);
      }
      if (period === "to") {
        setEmailSameTo(value.target.value);
      }
    }
    if (day === "Mon") {
      if (period === "from") {
        setEmailMonFrom(value.target.value);
      }
      if (period === "to") {
        setEmailMonTo(value.target.value);
      }
    }
    if (day === "Tue") {
      if (period === "from") {
        setEmailTueFrom(value.target.value);
      }
      if (period === "to") {
        setEmailTueTo(value.target.value);
      }
    }
    if (day === "Wed") {
      if (period === "from") {
        setEmailWedFrom(value.target.value);
      }
      if (period === "to") {
        setEmailWedTo(value.target.value);
      }
    }
    if (day === "Thu") {
      if (period === "from") {
        setEmailThuFrom(value.target.value);
      }
      if (period === "to") {
        setEmailThuTo(value.target.value);
      }
    }
    if (day === "Fri") {
      if (period === "from") {
        setEmailFriFrom(value.target.value);
      }
      if (period === "to") {
        setEmailFriTo(value.target.value);
      }
    }
  };

  const toggleSameEveryDay = (e) => {
    if (e.target.name === "phone") {
      setPhoneCallSameEveryDay(e.target.checked);
    }
    if (e.target.name === "text") {
      setTextSameEveryDay(e.target.checked);
    }
    if (e.target.name === "email") {
      setEmailSameEveryDay(e.target.checked);
    }
  };

  const selectPhoneDay = (day) => {
    if (phoneDays.includes(day)) {
      setPhoneDays(phoneDays.filter((e) => e !== day));
    } else {
      setPhoneDays((phoneDays) => [...phoneDays, day]);
    }
  };

  const selectTextDay = (day) => {
    if (textDays.includes(day)) {
      setTextDays(textDays.filter((e) => e !== day));
    } else {
      setTextDays((textDays) => [...textDays, day]);
    }
  };

  const selectEmailDay = (day) => {
    if (emailDays.includes(day)) {
      setEmailDays(emailDays.filter((e) => e !== day));
    } else {
      setEmailDays((emailDays) => [...emailDays, day]);
    }
  };

  const renderPhoneTimePeriodByDays = () => {
    return phoneDays.map((day, i) => {
      let defaultValueFrom;
      let defaultValueTo;

      if (day === "Mon") {
        defaultValueFrom = phoneCallMonFrom;
        defaultValueTo = phoneCallMonTo;
      }

      if (day === "Tue") {
        defaultValueFrom = phoneCallTueFrom;
        defaultValueTo = phoneCallTueTo;
      }

      if (day === "Wed") {
        defaultValueFrom = phoneCallWedFrom;
        defaultValueTo = phoneCallWedTo;
      }

      if (day === "Thu") {
        defaultValueFrom = phoneCallThuFrom;
        defaultValueTo = phoneCallThuTo;
      }

      if (day === "Fri") {
        defaultValueFrom = phoneCallFriFrom;
        defaultValueTo = phoneCallFriTo;
      }

      return (
        <div className="days-time-period-container" key={i}>
          <div className="label">{day}</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => phoneTimeChange(value, "from", day)}
            input={
              <InputBase
                value={defaultValueFrom || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
          <div className="to-label">to</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => phoneTimeChange(value, "to", day)}
            input={
              <InputBase
                value={defaultValueTo || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
        </div>
      );
    });
  };

  const renderTextTimePeriodByDays = () => {
    return textDays.map((day, i) => {
      let defaultValueFrom;
      let defaultValueTo;

      if (day === "Mon") {
        defaultValueFrom = textMonFrom;
        defaultValueTo = textMonTo;
      }

      if (day === "Tue") {
        defaultValueFrom = textTueFrom;
        defaultValueTo = textTueTo;
      }

      if (day === "Wed") {
        defaultValueFrom = textWedFrom;
        defaultValueTo = textWedTo;
      }

      if (day === "Thu") {
        defaultValueFrom = textThuFrom;
        defaultValueTo = textThuTo;
      }

      if (day === "Fri") {
        defaultValueFrom = textFriFrom;
        defaultValueTo = textFriTo;
      }

      return (
        <div className="days-time-period-container" key={i}>
          <div className="label">{day}</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => textTimeChange(value, "from", day)}
            input={
              <InputBase
                value={defaultValueFrom || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
          <div className="to-label">to</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => textTimeChange(value, "to", day)}
            input={
              <InputBase
                value={defaultValueTo || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
        </div>
      );
    });
  };

  const renderEmailTimePeriodByDays = () => {
    return emailDays.map((day, i) => {
      let defaultValueFrom;
      let defaultValueTo;

      if (day === "Mon") {
        defaultValueFrom = emailMonFrom;
        defaultValueTo = emailMonTo;
      }

      if (day === "Tue") {
        defaultValueFrom = emailTueFrom;
        defaultValueTo = emailTueTo;
      }

      if (day === "Wed") {
        defaultValueFrom = emailWedFrom;
        defaultValueTo = emailWedTo;
      }

      if (day === "Thu") {
        defaultValueFrom = emailThuFrom;
        defaultValueTo = emailThuTo;
      }

      if (day === "Fri") {
        defaultValueFrom = emailFriFrom;
        defaultValueTo = emailFriTo;
      }

      return (
        <div className="days-time-period-container" key={i}>
          <div className="label">{day}</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => emailTimeChange(value, "from", day)}
            input={
              <InputBase
                value={defaultValueFrom || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
          <div className="to-label">to</div>
          <Select
            className="time-picker-selector"
            onChange={(value) => emailTimeChange(value, "to", day)}
            input={
              <InputBase
                value={defaultValueTo || ""}
                className="input input-medium"
              />
            }
          >
            {timePeriods.length !== 0 &&
              timePeriods.map((state, i) => (
                <MenuItem key={i} value={state.value}>
                  {state.value}
                </MenuItem>
              ))}
          </Select>
        </div>
      );
    });
  };

  const changePrimaryInfo = (e) => {
    if (e.target.name === "phone") {
      if (e.target.value.length > 14) {
        return;
      }
      setUserPhone(formattingPhoneNumber(e.target.value));
      if (e.target.value === "") {
        setUserPhone(null);
      }
    }
    if (e.target.name === "email") {
      setIsMailValid(validateEmail(e.target.value));
      setUserEmail(e.target.value);
    }
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      scrollable={true}
      className="contact-preference-modal"
    >
      <ModalBody>
        {loading ? <LoadingSpinner middleFixed={true} /> : null}
        <div className="mod-header">
          <span>Contact Preference</span>
          <button className="close-modal-btn" onClick={() => toggle()}>
            <Close />
          </button>
        </div>
        <div className="body-container">
          <div className="timezone-selector">
            <div className="title">Please select your timezone</div>
            <Select
              className="time-picker-selector timezone"
              onChange={(value) => selectTimeZone(value)}
              input={
                <InputBase
                  value={selectedTimezone || ""}
                  className="input input-medium"
                />
              }
            >
              {timezonePeriods.length !== 0 &&
                timezonePeriods.map((state, i) => (
                  <MenuItem key={i} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="switcher">
            <Switch
              checked={phoneCallToggleState}
              name="phone"
              onChange={toggleByType}
            />
            <span className="label">Phone Call</span>
            <input
              className="primary-info-input"
              type="text"
              name="phone"
              value={formattingPhoneNumber(userPhone)}
              onChange={changePrimaryInfo}
            />
          </div>
          {phoneCallToggleState ? (
            <>
              <div className="days-selector">
                {weekDaysMapper.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`day-container ${
                        phoneDays.includes(item) ? "active" : "inactive"
                      }`}
                      onClick={() => selectPhoneDay(item)}
                    >
                      {phoneDays.includes(item) && <CheckIcon />}
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="same-container">
                <Checkbox
                  className="checkbox-value"
                  checked={phoneCallSameEveryDay}
                  onChange={toggleSameEveryDay}
                  name="phone"
                  size="small"
                />
                <span className="same-label">Same every day</span>
              </div>
              <div className="time-period">
                {phoneCallSameEveryDay ? (
                  <div className="same-periods">
                    <Select
                      className="time-picker-selector"
                      onChange={(value) =>
                        phoneTimeChange(value, "from", "same")
                      }
                      input={
                        <InputBase
                          value={phoneCallSameFrom || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                    <div className="to-label">to</div>
                    <Select
                      className="time-picker-selector"
                      onChange={(value) => phoneTimeChange(value, "to", "same")}
                      input={
                        <InputBase
                          value={phoneCallSameTo || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ) : (
                  <>{renderPhoneTimePeriodByDays()}</>
                )}
              </div>
            </>
          ) : null}
          <div className="switcher">
            <Switch
              checked={textToggleState}
              name="text"
              onChange={toggleByType}
            />
            <span className="label">Text</span>
            {/* <input
              className="primary-info-input"
              type="number"
              name="phone"
              value={userPhone} 
              onChange={changePrimaryInfo} 
            /> */}
          </div>
          {textToggleState ? (
            <>
              <div className="days-selector">
                {weekDaysMapper.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`day-container ${
                        textDays.includes(item) ? "active" : "inactive"
                      }`}
                      onClick={() => selectTextDay(item)}
                    >
                      {textDays.includes(item) && <CheckIcon />}
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="same-container">
                <Checkbox
                  className="checkbox-value"
                  checked={textSameEveryDay}
                  onChange={toggleSameEveryDay}
                  name="text"
                  size="small"
                />
                <span className="same-label">Same every day</span>
              </div>
              <div className="time-period">
                {textSameEveryDay ? (
                  <div className="same-periods">
                    <Select
                      className="time-picker-selector"
                      onChange={(value) =>
                        textTimeChange(value, "from", "same")
                      }
                      input={
                        <InputBase
                          value={textSameFrom || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                    <div className="to-label">to</div>
                    <Select
                      className="time-picker-selector"
                      onChange={(value) => textTimeChange(value, "to", "same")}
                      input={
                        <InputBase
                          value={textSameTo || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ) : (
                  <>{renderTextTimePeriodByDays()}</>
                )}
              </div>
            </>
          ) : null}
          <div className="switcher">
            <Switch
              checked={emailToggleState}
              name="email"
              onChange={toggleByType}
            />
            <span className="label">Email</span>
            <input
              className={`${isMailValid ? "" : "invalid"} primary-info-input`}
              type="text"
              name="email"
              value={userEmail}
              onChange={changePrimaryInfo}
            />
          </div>
          <div
            className={`${profileInfo?.esign ? "" : "send"} esign-btn`}
            onClick={() => sendEsignLetter()}
          >
            <div
              className={`${profileInfo?.esign ? "" : "send"} image-container`}
            >
              {profileInfo?.esign ? (
                <img className="white-check" src={whiteCheck} />
              ) : (
                <img className="white-check" src={planeIcon} />
              )}
            </div>
            {profileInfo?.esign === true && <span>E-Sign Authorized</span>}
            {profileInfo?.esign === false && (
              <span>Send E-Sign Authorization</span>
            )}
            {profileInfo?.esign === null && <span>E-Sign Status Pending</span>}
          </div>
          {emailToggleState ? (
            <>
              <div className="days-selector">
                {weekDaysMapper.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={`day-container ${
                        emailDays.includes(item) ? "active" : "inactive"
                      }`}
                      onClick={() => selectEmailDay(item)}
                    >
                      {emailDays.includes(item) && <CheckIcon />}
                      {item}
                    </div>
                  );
                })}
              </div>
              <div className="same-container">
                <Checkbox
                  className="checkbox-value"
                  checked={emailSameEveryDay}
                  onChange={toggleSameEveryDay}
                  name="email"
                  size="small"
                />
                <span className="same-label">Same every day</span>
              </div>
              <div className="time-period">
                {emailSameEveryDay ? (
                  <div className="same-periods">
                    <Select
                      className="time-picker-selector"
                      onChange={(value) =>
                        emailTimeChange(value, "from", "same")
                      }
                      input={
                        <InputBase
                          value={emailSameFrom || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                    <div className="to-label">to</div>
                    <Select
                      className="time-picker-selector"
                      onChange={(value) => emailTimeChange(value, "to", "same")}
                      input={
                        <InputBase
                          value={emailSameTo || ""}
                          className="input input-medium"
                        />
                      }
                    >
                      {timePeriods.length !== 0 &&
                        timePeriods.map((state, i) => (
                          <MenuItem key={i} value={state.value}>
                            {state.value}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                ) : (
                  <>{renderEmailTimePeriodByDays()}</>
                )}
              </div>
            </>
          ) : null}
        </div>
        <div className="mod-footer">
          <CustomButton
            text="Cancel"
            onClickHandler={() => toggle()}
            optionalClass="cancel-btn"
          />
          <CustomButton
            text="Save Preference"
            onClickHandler={sendContactPreference}
            disabled={loading}
            optionalClass="confirm-btn"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
