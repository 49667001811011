import moment from "moment";
import { getBlockingDates } from "lib/api/common";

import { useSelector } from "react-redux";

export const isEquivalent = (obj1, obj2) => {
  // Create arrays of property names
  var obj1Props = Object.getOwnPropertyNames(obj1);
  var obj2Props = Object.getOwnPropertyNames(obj2);

  // If number of properties is different,
  // objects are not equivalent
  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (var i = 0; i < obj1Props.length; i++) {
    var propName = obj1Props[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (obj1[propName] !== obj2[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

export const addHistoryType = (history, type) => {
  const newHistory = [];
  history.map((historyItem) => newHistory.push({ ...historyItem, type }));
  return newHistory;
};

export const accountNumberLastFour = (accountNumber) => {
  if (!accountNumber) return;
  return accountNumber.account_number?.length <= 4
    ? accountNumber
    : accountNumber.slice(accountNumber.length - 4, accountNumber.length);
};

export const nearestDate = (datesToBeChecked = [], dateToCheckFor) => {
  let nearestDate;

  datesToBeChecked.forEach((date) => {
    let diff = moment(date).diff(moment(dateToCheckFor), "days");
    if (diff > 0) {
      if (nearestDate) {
        if (moment(date).diff(moment(nearestDate), "days") < 0) {
          nearestDate = date;
        }
      } else {
        nearestDate = date;
      }
    }
  });

  return nearestDate;
};


export const getBlockingDatesFunc = async(
  setBlockingDates, 
  setInitialDate, 
  id, 
  isChangePaymentMethod,
  monthDay,
) => {
  let formattedBlockingDates = [];
  const tomorrow = moment().add(1, "days");
  const formattedTomorrow = moment(tomorrow).format("YYYY-MM-DD");
  const data = await getBlockingDates(id);

  if (data.success === "True") {
    data.data.map((item) => {
      formattedBlockingDates.push(moment(item).format("YYYY-MM-DD"))
    })
    setBlockingDates(formattedBlockingDates);

    if (!isChangePaymentMethod) {
      if (monthDay === null && formattedBlockingDates.indexOf(formattedTomorrow) === -1) {
        setInitialDate();
      }
    }
  }
}

export const showTitle = (pageType, sendUniverseTitle, tenantName) => {

  if (tenantName) {
    return sendUniverseTitle ? `${tenantName}` : `${pageType} - ${tenantName}`
  } else {

      const multitenancyCompanyShortName = useSelector(
        (state) => state.app.tenantSettings?.company_short_name || ""
      );
      const featureFlags = useSelector(
        (state) => state.app.tenantSettings?.feature_flags || []
      );
      const universalTitle = featureFlags.some(
        (flag) => flag.name === "universal_title" && flag.is_enabled
      );

    return universalTitle ? `${multitenancyCompanyShortName}` : `${pageType} - ${multitenancyCompanyShortName}`
  }  
}