import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";

import { formattedMoney } from "lib/helpers/formatters";

import { useTranslation } from "react-i18next";
import { SuccessTitle } from "components/SuccessTitle";
import "./style.scss";

export const FullPaymentSuccessModal = ({
  modal,
  parentModal,
  parentToggle,
  selectedPaymentObject,
  dateValue,
  debt,
  consumerPossiblePlan,
  asaFlow,
}) => {
  const { t } = useTranslation();

  const closeModal = () => {
    window.location.replace("/");
  };

  const renderSubtitle = () => {
    return (
      <div className="subtitle">
        {asaFlow
          ? t(
              "Please note, your offer has not been reviewed by an attorney and may not be accepted. You will receive a written notice when your agreement has been reviewed."
            )
          : t(
              "Thank you for resolving your balance. You will receive a receipt."
            )}
      </div>
    );
  };
  const renderPaymentMethodValue = () => {
    let value;
    // for existing Debit Card
    if (selectedPaymentObject?.CardName) {
      value = selectedPaymentObject.CardName.slice(-4);
      return `•••• ${value}`;
    }
    // for new Debit Card
    if (selectedPaymentObject?.card_name) {
      value = selectedPaymentObject.card_name.slice(-4);
      return `•••• ${value}`;
    }
    // for existing Account Number
    if (selectedPaymentObject?.AccountNumber) {
      value = selectedPaymentObject.AccountNumber.slice(-4);
      return `•••• ${value}`;
    }
    // for new Account Number
    if (selectedPaymentObject?.account_number) {
      value = selectedPaymentObject.account_number.slice(-4);
      return `•••• ${value}`;
    }
    if (selectedPaymentObject?.Email) {
      return "PayPal";
    }
    if (selectedPaymentObject?.email) {
      return "PayPal";
    }
  };

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  };

  const setStepDetails = () => {
    window.location.replace("/");
  };

  return (
    <>
      <Modal
        isOpen={modal}
        onOpened={closeParentModal}
        className="full-payment-success-modal"
        id="final-details"
        centered
      >
        <ModalBody>
          <div className="mod-header">
            <button className="close-modal-btn" onClick={() => closeModal()}>
              <Close />
            </button>
          </div>
          <div className="mod-body">
            <div className="title">
              <SuccessTitle />
            </div>
            {renderSubtitle()}
            <div className="success-info-container">
              <div className="info-row">
                <span className="label">{t("Total Amount")}</span>
                <span className="value">
                  $
                  {formattedMoney(
                    debt.plan_balance - consumerPossiblePlan.discount
                  )}
                </span>
              </div>
              <div className="info-row">
                <span className="label">{t("Payment Method")}</span>
                <span className="value">{renderPaymentMethodValue()}</span>
              </div>
              <div className="info-row">
                <span className="label">{t("Transaction Date")}</span>
                <span className="value">{moment(dateValue).format("ll")}</span>
              </div>
            </div>
            <div className="mod-footer">
              <div
                className="download-receipt-btn"
                onClick={() => setStepDetails()}
              >
                <span id="see-plan-details">{t("See Plan Details")}</span>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

FullPaymentSuccessModal.propTypes = {
  parentModal: PropTypes.bool,
  parentToggle: PropTypes.any,
  selectedPaymentObject: PropTypes.object,
  dateValue: PropTypes.any,
  debt: PropTypes.object,
  consumerPossiblePlan: PropTypes.any,
};
