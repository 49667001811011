export const consumerFormPrimaryItems = [
  { title: "Full Name", name: "full_name", disabled: true },
  { title: "Alias", name: "alias", disabled: false },
  { title: "Email", name: "email", disabled: false },
  { title: "Birth Date", name: "birth_date", disabled: true },
  { title: "SSN", name: "ssn", disabled: true },
  { title: "Access Code", name: 'access_code', disabled: true},
  { title: "Language", name: 'language', disabled: false}, 
  { title: "Other Language", name: 'other_language', disable: false }
];
export const consumerFormPhoneNumbersItems = [
  { title: "Cell Phone", name: "cell_phone", disabled: false },
  { title: "Home Phone", name: "home_phone", disabled: false },
];
export const consumerFormAddressItems = [
  { title: "State", name: "state", disabled: false },
  { title: "City", name: "city", disabled: false },
  { title: "Street", name: "street", disabled: false },
  { title: "ZIP", name: "zip", disabled: false },
];
