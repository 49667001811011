import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { FullPaymentSuccessModal } from "ConsumerPage/HowYouWantToPay/FullPaymentFlow/FullPaymentSuccessModal";
import { SPPSuccessModal } from "ConsumerPage/HowYouWantToPay/SelectPaymentPlanFlow/SPPSuccessModal";
import { LoadingSpinner } from "components/LoadingSpinner";
import { getDocuSignEnvelopUrl, getDocuSignEnvelopStatus, getAdminDocuSignEnvelopUrl } from "lib/api/common";
import { CancelSignModal } from "ConsumerPage/components/DocuSignIntegration/CancelSignModal";

import { asaStatuses } from "lib/helpers/constants";

import "./style.scss";
import { wtError } from "lib/helpers/dataLayers";

let count = 0;

export const EmbedDocuSignIframeModal = ({modal, toggle, parentModal, parentToggle, docuSignUrl, type, selectedPaymentObject, dateValue, debt, consumerPossiblePlan, setStep, onboarding, openExistingAgreement}) => {

  const { t } = useTranslation();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [successFullPaymentModal, setSuccessFullPaymentMethod] = useState(false);
  const toggleSuccessFullPayment = () => setSuccessFullPaymentMethod(!successFullPaymentModal);

  const [successSPPPaymentModal, setSuccessSPPPaymentMethod] = useState(false);
  const toggleSuccessSPPPayment = () => setSuccessSPPPaymentMethod(!successSPPPaymentModal);

  const [cancelSignModal, setCancelSignModal] = useState(false);
  const toggleCancelSignModal = () => setCancelSignModal(!cancelSignModal);

  const [SignUrl, setSignUrl] = useState('');

  const [confirmationStep, setConfirmationStep] = useState();
  const [showSignBtn, setShowSignBtn] = useState(false);

  useEffect(() => {
    const getExistingDocuSignUrl = async() => {
      setLoading(true);
      let data
      if (id) {
        data = await getAdminDocuSignEnvelopUrl(id, debt.referenceNumber);
      } else {
        data = await getDocuSignEnvelopUrl(debt.referenceNumber);
      }
      if (data.success === "True") {
        setLoading(false);
        await setSignUrl (data.envelope_url);
      } else {
        setLoading(false);
        wtError(data);
        alert.error(t(data.message));
      }
    }
    if (modal) {
      if (openExistingAgreement) {
        getExistingDocuSignUrl();
      } else {
        setSignUrl(docuSignUrl);
      }
      window.addEventListener("message", receiveMessageDocuSign, false);
    }
  },[modal]);

  async function receiveMessageDocuSign(event) {
    if (count > 0) return;

    if (event.data === "SIGNED_SUCCESS") { 
      count++;
      afterSuccessSigned();
    }
  }

  const afterSuccessSigned = async() => {

    if (id) {
      toggle();
      return
    }

    const data = await getDocuSignEnvelopStatus(debt.referenceNumber);

    if (data.success === "True" && openExistingAgreement) {
      window.location.replace("/");
      return;
    }

    if (data.envelope.status === asaStatuses.DECLINED_BY_CLIENT) {
      await setConfirmationStep(false);
      await setShowSignBtn(false);
      toggle();
      toggleCancelSignModal();
    } else if (data.envelope.status === asaStatuses.UNSIGNED) {
      await setConfirmationStep(true);
      await setShowSignBtn(false);
      toggle();
      toggleCancelSignModal();
    } else {

      if (onboarding) {
        switch (type) {
          case "FullPayment":
            setStep("FULLSuccess");
            break;
          case "SPPPayment":
            setStep("SPPSuccess");
            break;
          default:
            break;
        }
      } else {
        switch (type) {
          case "FullPayment":
            toggleSuccessFullPayment();
            break;
          case "SPPPayment":
            toggleSuccessSPPPayment();
            break;
          default:
            break;
        }
      }
    }
  }

  const closeParentModal = () => {
    if (parentModal) {
      parentToggle();
    }
  }

  const closeModalRedirect = async() => {
    if (!openExistingAgreement) {
      await setConfirmationStep(true);
      await setShowSignBtn(true);
      toggleCancelSignModal();
    } else {
      toggle();
    }
  }

  return (
    <>
      <Modal
        isOpen={modal}
        scrollable={true}
        onOpened={closeParentModal}
        className="docusign-modal"
      >
        <ModalBody>
          {loading ? (<LoadingSpinner middleFixed={true}/>) : null}
          <div className="mod-header">
            <span>{[asaStatuses.COMPLETED_BY_CLIENT, asaStatuses.COMPLETED_BY_ATTORNEY].includes(debt.asa_status) ? t("See Your Agreement") : t("Review and Sign Your Agreement")}</span>
            <button className="close-modal-btn" onClick={() => closeModalRedirect()}>
              <Close />
            </button>
          </div>
          <iframe id="iframe-modal" src={SignUrl} title="Add Card" frameBorder="0"/>
        </ModalBody>
      </Modal>
      {type === "FullPayment" && (
        <FullPaymentSuccessModal
          modal={successFullPaymentModal}
          selectedPaymentObject={selectedPaymentObject}
          parentModal={modal}
          parentToggle={toggle}
          dateValue={dateValue}
          debt={debt}
          consumerPossiblePlan={consumerPossiblePlan}
          asaFlow={true}
        />
      )}
      {type === "SPPPayment" && (
        <SPPSuccessModal
          modal={successSPPPaymentModal}
          parentModal={modal}
          parentToggle={toggle}
          asaFlow={true}
        />
      )}
      <CancelSignModal
        modal={cancelSignModal}
        toggle={toggleCancelSignModal}
        confirmationStep={confirmationStep}
        showSignBtn={showSignBtn}
      />
    </>
  )
}