import React from "react";
import PropTypes from "prop-types";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { formattedMoney } from "lib/helpers/formatters";
import { useTranslation } from "react-i18next";

import "./style.scss";

export const AccountDetails = ({ content, info, isAdmin }) => {
  const { t } = useTranslation();
  const standardClient = info.display_id === 1;

  const formattedDate = (date) => {
    if (!date) return null;
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const [year, month, day] = date.split("-");
    return `${months[Number(month) - 1]} ${day}, ${year}`;
  };

  const renderAccordion = (label, value, details) =>
    value ? (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <span className="label">{label}</span>
          <span className="value">{value}</span>
        </AccordionSummary>
        <AccordionDetails>{details}</AccordionDetails>
      </Accordion>
    ) : null;

  return (
    <div className="account-details">
      <div className="mod-header">
        <span>{t("Account Details")}</span>
      </div>
      <div className="mod-body">
        {renderAccordion(
          "Merchant",
          `${info.merchant}`,
          t(
            "This most commonly references the retailer or store who issued your account. Retailers partner with financial institutions, also referred to as the original creditor, to finance your account."
          ),
          isAdmin
        )}
        {renderAccordion(
          "Account Type",
          info.account_type,
          t("This is Account Type."),
          isAdmin
        )}
        {renderAccordion(
          "Settlement Limit",
          info.settlement_limit ? `${info.settlement_limit}%` : " ",
          t("This is Settlement Limit."),
          isAdmin
        )}
        {renderAccordion(
          content?.originalCreditor || t("Original Creditor"),
          info.originalCreditor,
          standardClient
            ? t("This is the company you originally owed money to.")
            : t("This is the brand that owns your account.")
        )}
        {renderAccordion(
          standardClient ? t("Current Creditor") : t("Branch"),
          info.currentCreditor,
          standardClient
            ? t(
                "This is the company that now owns your debt. For more information about how debt is bought and sold, see our Rights and Responsibilities page."
              )
            : t(
                "This is the local branch you are familiar with, and to whom you owe money"
              )
        )}
        {renderAccordion(
          content?.referenceNumber || t("Reference Number"),
          info.referenceNumber,
          t("This is the number we use in our system to track your account.")
        )}
        {renderAccordion(
          content?.accountNumber || t("Account Number"),
          info.originalAccountNumber?.slice(-4),
          t("This is the number on the account you originally opened.")
        )}
        {renderAccordion(
          t("Name on Account"),
          info.nameOnAccount,
          t(
            "This is the name associated with the original account. If this is not you, please let us know."
          )
        )}
        {renderAccordion(
          t("Current Interest Rate"),
          `${info.currentInterestRate}%`,
          t(
            "This is the legal rate at which your account is currently accruing interest."
          ),
          standardClient
        )}
        {renderAccordion(
          t("Placement Date"),
          formattedDate(info.placementDate),
          t(
            "This is the date the company that owns this account contracted us to work with you to collect on the debt."
          )
        )}
        {renderAccordion(
          t("Account Open Date"),
          formattedDate(info.accountOpenDate),
          t("This is the date you originally opened this account.")
        )}
        {renderAccordion(
          t("Last Payment Date"),
          formattedDate(info.lastPaymentDate),
          t("This is the date that your most recent payment posted.")
        )}
        {renderAccordion(
          t("Last Payment Amount"),
          `$ ${formattedMoney(info.lastPaymentAmount)}`,
          t("This is the amount of your last posted payment.")
        )}
        {renderAccordion(
          t("Charge Off Date"),
          formattedDate(info.chargeOffDate),
          t(
            "This is the date your account was written off by the original creditor."
          )
        )}
        {renderAccordion(
          t("Charge Off Balance"),
          `$ ${formattedMoney(info.chargeOffBalance)}`,
          t("This was the balance on your account when it was written off.")
        )}
        {renderAccordion(
          t("SOL Date"),
          formattedDate(info.sol_date),
          t(
            "The Statute of Limitations is the legal timeframe during which an account holder can be held liable for repayment. Once this period expires, legal actions to collect on the debt are no longer enforceable. Be mindful of this date when managing account activity."
          )
        )}
        {standardClient && info.judgment_date && (
          <>
            {renderAccordion(
              t("Judgment Date"),
              formattedDate(info.judgment_date),
              t(
                "This is the date the court rendered the judgment saying you need to repay this money."
              )
            )}
            {info.judgment_amount !== null &&
              renderAccordion(
                t("Judgment Amount"),
                `$ ${formattedMoney(info.judgment_amount)}`,
                t(
                  "The amount or amounts for which the claim is awarded at the time of judgment."
                )
              )}
          </>
        )}
        {standardClient && info.suit_filed && (
          <>
            {renderAccordion(
              t("Suit Filed Date"),
              formattedDate(info.suit_filed),
              t("The date your suit was filed with the court.")
            )}
            {renderAccordion(
              t("Suit Served Date"),
              formattedDate(info.suit_served),
              t("The date that your suit was served.")
            )}
          </>
        )}
        {renderAccordion(
          content?.judgementCase || t("Judgment Case"),
          info.judgementCase,
          content?.accordionDetails ||
            t(
              "This is the case number of the judgment saying you need to repay this money."
            )
        )}
        {renderAccordion(
          "Disconnect Date",
          formattedDate(info.disconnect_date),
          t("This is Disconnect Date."),
          isAdmin
        )}
        {renderAccordion(
          "Contract Address",
          info.contact_address,
          t("This is Contract Address."),
          isAdmin
        )}
        {renderAccordion(
          "Repo Date",
          formattedDate(info.repo_date),
          t("This is Repo Date."),
          isAdmin
        )}
        {renderAccordion(
          "Wage Garn Date",
          formattedDate(info.wage_garn_date),
          t("This is Wage Garn Date."),
          isAdmin
        )}
        {renderAccordion(
          "Bank Garn Date",
          formattedDate(info.bank_garn_date),
          t("This is Bank Garn Date."),
          isAdmin
        )}
        {renderAccordion(
          "Receivership Date",
          formattedDate(info.receivership_date),
          t("This is Receivership Date."),
          isAdmin
        )}
        {renderAccordion(
          "Lien Filed Date",
          formattedDate(info.lien_filed_date),
          t("This is Lien Filed Date."),
          isAdmin
        )}
      </div>
    </div>
  );
};

AccountDetails.propTypes = {
  info: PropTypes.object.isRequired,
  content: PropTypes.object,
  isAdmin: PropTypes.bool,
};
