import React, { useEffect, useState } from "react";
import GoDaddyLogo from "./images/godaddy.png";
import EntrustLogo from "./images/entrust.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import MultitenancyLogoPicker from "multitenancy/multitenancyLogoPicker";
import {
  multitenancyTerms,
  multitenancyPrivacy,
} from "multitenancy/multitenancyContactInfo";
import { useTranslation } from "react-i18next";
import { updateProfile } from "redux/actions/profile";
import ReactFlagsSelect from "react-flags-select";
import { getContentBlock } from "lib/api/consumer";
import { getTenant, tenant } from "multitenancy/multitenancyGetTenant";

import "./style.scss";

export const ConsumerFooter = ({ page }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const showLanguageSelector = useSelector((state) => state.profile.profileData?.profile?.allow_language_update);
  const consumerLanguage = useSelector((state) => state.profile.profileData?.profile?.language);
  const tenantSettings = useSelector((state) => state.app.tenantSettings);
  const [siteLanguage, setSiteLanguage] = useState("US");
  const [content, setContent] = useState();

  const featureFlags = useSelector(
    (state) => state.app.tenantSettings?.feature_flags || []
  );
  const hideLanguageSelector = featureFlags.some(
    (flag) => flag.name === "hide_language_selector" && flag.is_enabled
  );
  const hideHelpCenterSelector = featureFlags.some(
    (flag) => flag.name === "hide_help_center" && flag.is_enabled
  );

  useEffect(() => {
    if (showLanguageSelector) {
      setSiteLanguage(languageToCode(consumerLanguage));
      i18n.changeLanguage(languageToCode(consumerLanguage));
    }
  }, [consumerLanguage]);

  useEffect(() => {
    getContent();
  }, []);

  const getContent = async () => {
    const response = await getContentBlock("consumer-footer");
    if (response.success === "True") {
      const contentBody = JSON.parse(response.data?.body);
      setContent(contentBody);
    }
  };

  const languageToCodeMap = {
    English: "US",
    Español: "ES",
  };

  const codeToLanguageMap = {
    US: "English",
    ES: "Español",
  };

  const languageToCode = (language) => languageToCodeMap[language] || "US";
  const codeToLanguage = (code) => codeToLanguageMap[code] || "English";

  const handleChangeLanguage = (code) => {
    dispatch(updateProfile({ language: codeToLanguage(code) }));
    i18n.changeLanguage(code);
  };

  return (
    <div className="consumer-footer">
      <div className="top-blocks">
        <div className="links-container">
          <div className="logo">
            <MultitenancyLogoPicker />
          </div>
          <div className="links">
            {(window.location.hostname.toLowerCase() ===
              process.env.REACT_APP_INVESTINET_PROD ||
              window.location.hostname.toLowerCase() ===
                process.env.REACT_APP_INVESTINET_STAGE) && (
              <a
                className="heading heading-caption-bold"
                href="https://www.nmlsconsumeraccess.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                NMLS ID: 937604
              </a>
            )}
            {multitenancyTerms(tenantSettings?.terms)}
            {multitenancyPrivacy(tenantSettings?.privacy)}
            {page !== "/unsubscribe" && !hideHelpCenterSelector && (
              <a href="/help-center" className="heading">
                {t("Help Center")}
              </a>
            )}
            {getTenant() === tenant.TIDEWATER && (
              <a href="https://twcs.com/Text-Message-Policy" className="heading">
                {t("Text Message Policy")}
              </a>
            )}
          </div>
        </div>
        <div className="security-images">
          <img className="go-daddy" src={GoDaddyLogo} alt="GoDaddyLogo logo" />
          <img className="entrust" src={EntrustLogo} alt="EntrustLogo logo" />
        </div>
      </div>
      <div className="bottom-blocks">
        <div className="lang-cont">
          {showLanguageSelector && !hideLanguageSelector && (
            <ReactFlagsSelect
              className="language-dropdown"
              selected={siteLanguage}
              onSelect={handleChangeLanguage}
              countries={["US", "ES"]}
              customLabels={{ US: "ENG", ES: "ESP" }}
              placeholder="Select"
            />
          )}
          <span className="info">
            {t(content?.infoText)}
          </span>
        </div>
        <span className="copyright">
          {t("© All rights reserved,")} {moment().format("YYYY")}
        </span>
      </div>
    </div>
  );
};
