import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomButton } from "../ui-kit/CustomButtom";
import { useTranslation } from 'react-i18next';
import "./style.scss";

export const Cookies = ({ handleAcceptCookies }) => {
  const { t } = useTranslation();

  const privacyUrl = useSelector((state) => state.app.tenantSettings?.privacy);
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );
  const investinetPrivacy = "https://waythru.com/privacy-policy/";

  return (
    <div className="cookies">
      <p>
        {t("We use cookies to improve your experience on our site. To learn more, read our")}{" "}
        <a href={multitenancyCompanyShortName === "Tidewater" ? investinetPrivacy : privacyUrl} target="_blank" rel="noopener noreferrer">
          {t("Privacy Policy")}
        </a>.
      </p>
      <CustomButton text={t("Accept")} onClickHandler={handleAcceptCookies} />
    </div>
  );
};

Cookies.propTypes = {
  handleAcceptCookies: PropTypes.func,
};
