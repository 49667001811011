import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAlert } from "react-alert";
import Modal from "@material-ui/core/Modal";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useSelector } from "react-redux";

import "./style.scss";
import { SystemCloseIcon16 } from "../icons/SystemIcons";
import { createNewTag, renameTag } from "../../lib/api/admin";
import { CustomButton } from "../ui-kit/CustomButtom";
import { SingleTag } from "./components/SingleTag";

export const TagsBody = ({
  headerTitle,
  activeTags,
  externalTags,
  archivedTags,
  notesTags,
  type,
  fetchActiveTags,
  fetchExternalTags,
  fetchActiveArchivedTags,
  fetchExternalArchivedTags,
  setSelectedNoteTags,
  loading,
  activeCategories,
  externalCategories,
  showCategories
}) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [newTagName, setNewTagName] = useState(null);
  const [oldTagName, setOldTagName] = useState("");
  const [tagType, setTagType] = useState("Current Tags");
  const [newTagCategory, setNetTagCategory] = useState(null);
  const [tagToRename, setTagToRename] = useState("");
  const [modalType, setModalType] = useState("");

  const alert = useAlert();

  const featureFlags = useSelector(
    (state) => state.app.tenantSettings?.feature_flags || []
  );

  const cshEnabled = featureFlags.some(
    (flag) => flag.name === "customer_service_handling" && flag.is_enabled
  );

  const handleClose = () => {
    setNewTagName(null);
    setNetTagCategory(null);
    setOpen(false);
  };

  const handleNewTag = () => {
    setModalType("new-tag");
    setOpen(true);
  };

  const handleChangeName = (e) => {
    setNewTagName(e.target.value);
  };

  const handleChangeType = (e) => {
    setNetTagCategory(null);
    setTagType(e.target.value);
  }

  const handleChangeCategory = (e) => {
    setNetTagCategory(e.target.value);
  };

  const handleCreateNewTag = async () => {
    handleClose();
    const data = await createNewTag({ name: newTagName.trim(), category: newTagCategory, isExternal: tagType === "External Tags"});
    if (data.success === "True") {
      if (tagType === "Current Tags") {
        await fetchActiveTags();
      } else {
        await fetchExternalTags();
      }
    } else {
      alert.error(data.message);
    }
  };

  const handleRenameTag = async () => {
    await renameTag({ old_name: oldTagName, new_name: tagToRename });
    if (type === "external") {
      await fetchExternalTags();
    } else {
      await fetchActiveTags();
    }
  };

  const handleSelectTag = async (item) => {
    if (!activeTags && !archivedTags && !externalTags) {

      if (selectedTags.filter(e => e.name === item.name).length > 0) {
        await setSelectedTags(oldArray => oldArray.filter(obj => obj.name !== item.name));
        await setSelectedNoteTags(oldArray => oldArray.filter(obj => obj.name !== item.name));
      } else {
        await setSelectedTags(oldArray => [...oldArray, item]);
        await setSelectedNoteTags(oldArray => [...oldArray, item]);
      }

    } else {
      return;
    }
  };

  const TagsBodyModal =
    modalType === "rename" ? (
      <div className="tags-body__modal">
        <div className="modal__header">
          <h3 className="heading heading-3">Rename tag</h3>
          <button onClick={() => handleClose()} className="button-transparent">
            <SystemCloseIcon16 />
          </button>
        </div>
        <div className="modal__body">
          <label className="heading heading-caption-bold" htmlFor="tag-name">
            Tag name
          </label>
          <input
            id="tag-name"
            type="text"
            className="input input-medium"
            value={tagToRename}
            onChange={(e) => setTagToRename(e.target.value)}
          />
        </div>
        <div className="modal__footer">
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleClose}
          />
          <CustomButton text="Rename" onClickHandler={handleRenameTag} />
        </div>
      </div>
    ) : (
      <div className="tags-body__modal">
        <div className="modal__header">
          <h3 className="heading heading-3">New tag</h3>
          <button onClick={() => handleClose()} className="button-transparent">
            <SystemCloseIcon16 />
          </button>
        </div>
        <div className="modal__body">
          <div className="form-group">
            <label
              // component="tag-name"
              className="heading heading-caption-bold"
            >
              Tag name
            </label>
            <input
              id="tag-name"
              type="text"
              className="input input-medium"
              onChange={handleChangeName}
            />
          </div>
          <div className="form-group">
            <div className="flex-col">
              {cshEnabled && (
                <FormControl component="fieldset">
                  <label
                    // component="legend"
                    className="heading heading-caption-bold"
                  >
                    Type
                  </label>
                  <RadioGroup
                    aria-label="type"
                    name="type"
                    value={tagType}
                    onChange={handleChangeType}
                  >
                    <FormControlLabel
                      value="Current Tags"
                      control={<Radio color="primary" />}
                      label="Current Tags"
                    />
                    <FormControlLabel
                      value="External Tags"
                      control={<Radio color="primary" />}
                      label="External Tags"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              <FormControl component="fieldset">
                <label
                  // component="legend"
                  className="heading heading-caption-bold"
                >
                  Category
                </label>
                <RadioGroup
                  aria-label="category"
                  name="category"
                  value={newTagCategory}
                  onChange={handleChangeCategory}
                >
                  {tagType === "Current Tags" ? (
                    activeCategories.map((item, i) => {
                      return (
                        <FormControlLabel
                          key={i}
                          value={item}
                          control={<Radio color="primary" />}
                          label={item}
                        />
                      )
                    })
                  ) : (
                    externalCategories.map((item, i) => {
                      return (
                        <FormControlLabel
                          key={i}
                          value={item}
                          control={<Radio color="primary" />}
                          label={item}
                        />
                      )
                    })
                  )}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
        <div className="modal__footer">
          <CustomButton
            type="secondary"
            text="Cancel"
            onClickHandler={handleClose}
          />
          <CustomButton 
            text="Create" 
            onClickHandler={handleCreateNewTag}
            disabled={!newTagCategory || !newTagName}
          />
        </div>
      </div>
    );

  return (
    <div className="tags-body">
      <div className="d-flex justify-content-between">
        {headerTitle && <h2 className="heading heading-2">{headerTitle}</h2>}
        {(activeTags || externalTags) && (
          <CustomButton text="New Tag" onClickHandler={handleNewTag} />
        )}
      </div>
      <div className="tags-body__main">
        {showCategories.map((item, i) => {
          return (
            <div className="tags-body__block" key={i}>
              <h4 className="heading heading-body-bold">{item}</h4>
              <div className="tags-body__tags">
                {loading ? (
                  <div>Loading</div>
                ) : (
                  (activeTags || externalTags || archivedTags || notesTags)[item].map((tag, i) => (
                    <SingleTag
                      key={i}
                      id={tag.id}
                      selected={selectedTags.filter(e => e.name === tag).length > 0}
                      clickHandler={handleSelectTag}
                      category={item}
                      name={tag}
                      type={type}
                      fetchActiveTags={fetchActiveTags}
                      fetchExternalTags={fetchExternalTags}
                      fetchActiveArchivedTags={fetchActiveArchivedTags}
                      fetchExternalArchivedTags={fetchExternalArchivedTags}
                      setOldTagName={setOldTagName}
                      setModalType={setModalType}
                      setOpen={setOpen}
                      setTagToRename={setTagToRename}
                      activeTags={activeTags}
                      externalTags={externalTags}
                      archivedTags={archivedTags}
                    />
                  ))
                )}
              </div>
            </div>
          )
        })}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {TagsBodyModal}
      </Modal>
    </div>
  );
};

TagsBody.propTypes = {
  headerTitle: PropTypes.string,
  activeTags: PropTypes.any,
  archivedTags: PropTypes.any,
  notesTags: PropTypes.object,
  fetchActiveTags: PropTypes.func,
  fetchArchivedTags: PropTypes.func,
  setSelectedNoteTags: PropTypes.func,
  loading: PropTypes.bool,
};
