import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { fetchPlanInfo } from "redux/actions/planInfo";
import { formattedMoney } from "lib/helpers/formatters";

import { useTranslation } from 'react-i18next';

import { asaStatuses } from "lib/helpers/constants";

import { FileIcon } from "scss/multitenantIcons/fileIcon";
import { EmbedDocuSignIframeModal } from "ConsumerPage/components/DocuSignIntegration/EmbedDocuSignIframeModal";

import "./style.scss";

export const AsaPlan = ({debt, consumerId}) => {
  const [embedDocuSignIframeModal, setEmbedDocuSignIframeModal] = useState(false);
  const toggleEmbedDocuSignIframeModal = () => setEmbedDocuSignIframeModal(!embedDocuSignIframeModal);

  const dispatch = useDispatch();
  const { t } = useTranslation();  
  const planInfo = useSelector((state) => state.planInfo.planInfo?.data);

  useEffect(() => {
    dispatch(fetchPlanInfo(debt.plan_id, consumerId));
  }, []);


  const planHeader = () => {
    return (
      (planInfo && planInfo.payments === 1 && <span>{t("One Payment")}</span>) ||
      (planInfo && planInfo.payment_frequency === "Weekly" && <span>{t("Per Week")}</span>) ||
      (planInfo && planInfo.payment_frequency === "Monthly" && <span>{t("Per Month")}</span>) ||
      (planInfo && planInfo.payment_frequency === "Bi-Weekly" && <span>{t("Biweekly")}</span>)
    );
  };

  const renderStatusBar = () => {
    if (debt.asa_status === asaStatuses.UNSIGNED) {
      return (
        <div className="asa-status pending">
          {t("SIGN AGREEMENT")}
        </div>
      )
    }
    if (debt.asa_status === asaStatuses.COMPLETED_BY_CLIENT) {
      return (
        <div className="asa-status pending">
          {t("PENDING AGREEMENT")}
        </div>
      )
    }
  }

  const getAsaDocument = () => {
    toggleEmbedDocuSignIframeModal();
  }

  return (
    <>
      <div className="payment-plan-container" id="plan-info">
        <div className="title">
          <div>{t("Payment Plan")}</div>
          {renderStatusBar()}
        </div>
        <div className="body">
          <div className="block-container">
            <span className="label">{planHeader()}</span>
            <span className="value">$ {formattedMoney(planInfo?.per_month)}</span>
          </div>
          <div className="block-container">
            <span className="label">{t("Payments")}</span>
            <span className="value">{planInfo?.payments}</span>
          </div>
          {planInfo?.is_open_ended ? (
            <div className="block-container">
              <span className="label">{t("Plan Promise")}</span>
              <span className="value">$ {formattedMoney(planInfo?.plan_promise)}</span>
            </div>
          ) : (
            <div className="block-container">
              <span className="label">{t("Discount")}</span>
              <span className="value">$ {formattedMoney(planInfo?.discount)}</span>
            </div>
          )}
          <div className="block-container">
            <span className="label">{t("Start Date")}</span>
            <span className="value">{moment(planInfo?.start_date).format("ll")}</span>
          </div>
          <div className="block-container">
            <span className="label">{t("End Date")}</span>
            <span className="value">{moment(planInfo?.end_date).format("ll")}</span>
          </div>

        </div>
        {debt.asa_status === asaStatuses.COMPLETED_BY_CLIENT && (
          <div className="footer">
            <div className="see-agreement-btn" onClick={() => getAsaDocument()}>
              <FileIcon />
              <span>See Agreement</span>
            </div>
          </div>
        )}
      </div>
      {embedDocuSignIframeModal && (        
        <EmbedDocuSignIframeModal
          modal={embedDocuSignIframeModal}
          toggle={toggleEmbedDocuSignIframeModal}
          debt={debt}
          openExistingAgreement={true}
        />
      )}
    </>
  )
}