import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { CustomButton } from "components/ui-kit/CustomButtom";
import moment from "moment";

import "./style.scss";

export const CancelSignModal = ({
  modal,
  toggle,
  confirmationStep,
  showSignBtn,
}) => {
  const { t } = useTranslation();

  const phone = useSelector(
    (state) => state.app.tenantSettings?.tenant?.phone_number
  );
  const telUrl = useSelector((state) => state.app.tenantSettings?.tel_url);

  const renderTitle = () => {
    return (
      <div className="title">
        {confirmationStep
          ? t("You have not finished signing your contract")
          : t("You have declined the agreement")}
      </div>
    );
  };

  const renderSubtitle = () => {
    return confirmationStep ? (
      <div className="subtitle">
        {t(
          "You have not finished signing your contract, your payments will not be processed until this has been completed. Your contract will remain available for review and completion until "
        )}{" "}
        {moment().add(1, "days").format("ll")}.
      </div>
    ) : (
      <div className="subtitle">
        {t(
          "Please select a different payment plan and complete your agreement using the payment options available on your account. If you need assistance, contact us at "
        )}{" "}
        <a href={telUrl}>{phone}</a>.
      </div>
    );
  };

  const goToCabinet = () => {
    window.location.replace("/cabinet-active");
  };

  const openPreviousModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={modal} className="cancel-docusign-modal" centered>
      <ModalBody>
        <div className="mod-header">
          <button className="close-modal-btn" onClick={() => goToCabinet()}>
            <Close />
          </button>
        </div>
        <div className="mod-body">
          {renderTitle()}
          {renderSubtitle()}
        </div>
        <div className={`mod-footer ${showSignBtn ? "space" : "center"}`}>
          <div className="download-receipt-btn" onClick={() => goToCabinet()}>
            <span id="see-plan-details">
              {confirmationStep
                ? t("See Plan Details")
                : t("Go to Your Account")}
            </span>
          </div>
          {showSignBtn && (
            <CustomButton
              text={t("Sign")}
              onClickHandler={() => openPreviousModal()}
              optionalClass="sign-btn"
            />
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};
