import { useCallback, useState, useEffect } from "react";

const storageName = "userData";

export const useAuth = () => {
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem(storageName))?.token || null
  );
  const [userId, setUserId] = useState(null);
  const [role, setRole] = useState(
    JSON.parse(localStorage.getItem(storageName))?.role || null
  );

  const login = useCallback((jwtToken, id, role) => {
    setToken(jwtToken);
    setUserId(id);
    setRole(role);

    let expirationDate

    if (role === "admin" || role === "agent") { 
      expirationDate = new Date(new Date().getTime() + 1000 * 60 * 10);
    } else {
      expirationDate = new Date(new Date().getTime() + 1000 * 60 * 5);
    }

    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        token: jwtToken,
        expirationTime: expirationDate.toISOString(),
        role,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem(storageName);
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token && data.role) {
      login(data.token, data.userId, data.role);
    }
  }, [login]);
  const firstLogin = JSON.parse(localStorage.getItem("firstLogin"));

  return { login, logout, token, userId, role, firstLogin };
};
