import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { DebtPlan } from "../../../../components/DebtCustomCard/DebtPlan";
import { formattedMoney } from "../../../../lib/helpers/formatters";
import { Helmet } from "react-helmet";

import "./style.scss";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { showTitle } from "lib/helpers/other";

export const ReviewPlan = ({
  planInfo,
  planId,
  creditor,
  debtSum,
  isPlanEstablished,
  standardClient,
}) => {
  const sum = localStorage.getItem("paymentSum");

  const headerText = () => {
    if (sum) {
      return `${isPlanEstablished ? "Extra" : "One Time"} Payment`;
    } else {
      return "Review Plan";
    }
  };

  return (
    <div className="review-plan">
      <h2 className="heading heading-2">{headerText()}</h2>
      {!sum && <p className="heading heading-body-bold">{creditor} Account</p>}
      {sum ? (
        <Fragment>
          {debtSum ? (
            <div>
              <Helmet>
                <title>
                  {showTitle("One Time Payment")}
                </title>
              </Helmet>
              <p className="heading heading-body-medium">
                You are about to be charged ${sum ? formattedMoney(sum) : ""}.
                After this {isPlanEstablished ? "extra" : "one-time"} payment your
                current balance on this debt will be $
                {debtSum && sum ? formattedMoney(+debtSum - +sum) : ""}.
              </p>
            </div>

          ) : (
            <LoadingSpinner middleFixed={true}/>
          )}
        </Fragment>
      ) : (
        <DebtPlan
          data={planInfo}
          planId={planId}
          isPlanSelected
          preview
          standardClient={standardClient}
        />
      )}
    </div>
  );
};

ReviewPlan.propTypes = {
  planInfo: PropTypes.object,
  planId: PropTypes.number,
  creditor: PropTypes.string,
  debtSum: PropTypes.number,
  isPlanEstablished: PropTypes.bool,
  standardClient: PropTypes.number,
};
