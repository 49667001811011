import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useAlert } from "react-alert";

import { ReviewPlan } from "./components/ReviewPlan";
import { PaymentDate } from "./components/PaymentDate";
import { PaymentMethod } from "./components/PaymentMethod";
import { CardPayment } from "./components/CardPayment";
import { BankAccountPayment } from "./components/BankAccountPayment";
// import { ACHIframe } from "./components/ACHIframe";
import { Success } from "./components/Success";
import { addBankAccount, getDebtInfo } from "lib/api/consumer";
import {
  getPlanInfo,
  submitOneTimePayment,
  submitPayment,
  submitExtraPayment,
} from "../../lib/api/common";
import { getConsumerDebtInfo, getBalance } from "../../lib/api/admin";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import {
  setRequest,
  setIsNextButtonDisabled,
} from "../../redux/actions/setupPlan";

import { fetchBalance } from "../../redux/actions/balance";
import {
  fetchSavedCards,
  fetchSavedBankAccounts,
  isStepSkipped,
  getSteps,
} from "./helpers";
import "./style.scss";
import {
  paymentDateDataLayer,
  reviewPlanDataLayer,
  paymentMethodDataLayer,
  paymentSuccessfulDataLayer,
} from "../../lib/helpers/dataLayers";
import { CustomButton } from "../../components/ui-kit/CustomButtom";

import { fetchConsumerProfile } from "redux/actions/profile";
import { showTitle } from "lib/helpers/other";


export const SetupPlan = () => {
  const alert = useAlert();
  const { planId, debtId, id } = useParams();
  const sum = localStorage.getItem("paymentSum");

  const dispatch = useDispatch();
  const accountPaymentRequest = useSelector(
    (state) => state.setupPlan.accountPaymentRequest
  );
  const isNextButtonDisabled = useSelector(
    (state) => state.setupPlan.isNextButtonDisabled
  );
  const paymentDates = useSelector((state) => state.setupPlan.paymentDates);
  const balance = useSelector((state) => state.balance.balanceData.debts_info);

  const getStartDayFromPlan = useSelector((state) => {
    const startDayAsNumber = moment(state.setupPlan.paymentDates?.start_date).format("D");
    return startDayAsNumber
  })

  const [paymentType, setPaymentType] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isSuccess, setIsSuccess] = useState(false);
  const [debtInfo, setDebtInfo] = useState({});
  const [planInfo, setPlanInfo] = useState({});
  const [card, setCard] = useState("");
  const [account, setAccount] = useState("");
  let [startDay, setStartDay] = useState(null);
  const [defaultStartDay, setDefaultStartDay] = useState(null);
  const [isDateInvalid, setIsDateInvalid] = useState(false);
  const [savedCards, setSavedCards] = useState([]);
  const [savedBankAccounts, setSavedBankAccounts] = useState([]);
  const [selectedPaymentCard, setSelectedPaymentCard] = useState("new");
  const [selectedPaymentAccount, setSelectedPaymentAccount] = useState("new");
  const [skipped, setSkipped] = useState(new Set());
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("account");
  const [errorMessage, setErrorMessage] = useState("");

  const consumersData = useSelector((state) => state.profile.profileData);

  const steps = getSteps(sum);

  const todaysDate = new Date();
  const tklServerDay = moment().utcOffset(-300).format("DD");
  const localMachineDay = moment(todaysDate).format("DD");

  const setupStartDayForMonthlyToday = () => {
    if (paymentType === "monthly" || paymentType === "one time") {
      startDay = getStartDayFromPlan;
    } else {
      return
    }
  }

  useEffect(() => {

    _.isEmpty(consumersData) && dispatch(fetchConsumerProfile(id));
    fetchSavedCards(id, setSavedCards);
    fetchSavedBankAccounts(id, setSavedBankAccounts);
    fetchDebtAndPlanInfo();

    // need this if for reboot page
    if (!balance) {
      fetchConsumerBalance();
    }
    // eslint-disable-next-line
  }, [consumersData]);

  const fetchConsumerBalance = async () => {
    dispatch(fetchBalance(id));
  };

  const fetchDebtAndPlanInfo = async () => {
    setLoading(true);
    let debtData;
    let planData;

    if (id) {
      debtData = await getConsumerDebtInfo({
        client_id: id,
        debt_id: debtId,
      });

      if (!sum) {
        planData = await getPlanInfo({
          client_id: id,
          plan_id: planId,
        });
      }
    } else {
      setLoading(true);
      debtData = await getDebtInfo({
        debt_id: debtId,
      });

      if (!sum) {
        planData = await getPlanInfo({
          plan_id: planId,
        });
      }
    }

    if (!sum && planData.success === "False") {
      alert.error(`Error: ${planData.message}`);
      return;
    }

    !sum && setPaymentType(planData.data.payment_frequency.toLowerCase());
    !sum &&
      setStartDay(
        planData.data.payment_frequency.toLowerCase() === "monthly" ||
          planData.data.payment_frequency.toLowerCase() === "one time"
          ? moment().date() >= 28
            ? 1
            : tklServerDay !== localMachineDay 
              ? moment().add(2, "days").date() 
              : moment().add(1, "days").date()
          : 1
      );
    !sum &&
      setDefaultStartDay(
        planData.data.payment_frequency.toLowerCase() === "monthly" ||
          planData.data.payment_frequency.toLowerCase() === "one time"
          ? moment().date() >= 28
            ? 1
            : tklServerDay !== localMachineDay 
              ? moment().add(2, "days").date() 
              : moment().add(1, "days").date()
          : 1
      );
    setDebtInfo(debtData.data);
    !sum && setPlanInfo(planData.data);
    setLoading(false);
  };

  const handleSetAccountPaymentRequest = (e) => {
    e.persist();

    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: debtId,
        institution_number: 803,
        [e.target.name]: e.target.value,
      })
    );
  };

  const handleShowSuccess = () => {
    paymentSuccessfulDataLayer();
    setIsSuccess(true);
  };

  const setupPlanInfo = async () => {
    const planData = await getPlanInfo({
      client_id: id,
      plan_id: planId,
    });

    setPlanInfo(planData.data);
  }

  const calculatedDebtSum = (balance) => {
    const [filteredBalance] = balance.filter(
      (item) => item.id.toString() === debtId
    );
    return filteredBalance.current_balance;
  };

  function getStepContent(step) {
    // need this checking balance to prevent error
    if (!balance) return

    switch (step) {
      case 0:
        return (
          <ReviewPlan
            debtSum={balance[0] && calculatedDebtSum(balance)}
            creditor={debtInfo.originalCreditor}
            planInfo={planInfo}
            planId={debtInfo.plan_id}
            isPlanEstablished={!!debtInfo.loan_id}
            standardClient={debtInfo.display_id}
          />
        );
      case 1:
        if (sum)
          return (
            <PaymentMethod
              handleSelectPaymentMethod={setSelectedPaymentMethod}
              savedCards={savedCards}
              savedBankAccounts={savedBankAccounts}
              setSelectedPaymentCard={setSelectedPaymentCard}
              setSelectedPaymentAccount={setSelectedPaymentAccount}
            />
          );
        return (
          <PaymentDate
            startDay={+startDay}
            setStartDay={setStartDay}
            paymentsCount={planInfo.payments}
            setIsDateInvalid={setIsDateInvalid}
            paymentType={paymentType}
          />
        );
      case 2:
        setupStartDayForMonthlyToday();
        if (sum) {
          if (selectedPaymentMethod === "account") {
            // if (consumersData?.profile?.new_payment_system && selectedPaymentAccount == "" || consumersData?.profile?.new_payment_system && selectedPaymentAccount == "new") {
            //   return (
            //     <ACHIframe 
            //       handleBack={handleBack}
            //       handleShowSuccess={handleShowSuccess}
            //       startDay={startDay}
            //       planId={planInfo.id}
            //       planInfo={planInfo}
            //       // setCard={setCard}
            //       changePaymentMethod={false}
            //       isPlanEstablished={!!debtInfo.loan_id}
            //     />
            //   )
            // } else {              
              return (
                <BankAccountPayment
                  accountPaymentType={id ? "single-call" : "single-web"}
                  handleSetAccountPaymentRequest={handleSetAccountPaymentRequest}
                  planInfo={planInfo}
                  selectedPaymentAccount={selectedPaymentAccount}
                />
              );
            // }
          } else if (selectedPaymentMethod === "card") {
            return (
              <CardPayment
                handleBack={handleBack}
                handleShowSuccess={handleShowSuccess}
                startDay={startDay}
                planId={planInfo.id}
                planInfo={planInfo}
                setCard={setCard}
                setDebtInfo={setDebtInfo}
                changePaymentMethod={false}
                isPlanEstablished={!!debtInfo.loan_id}
              />
            );
          }
        }

        return (
          <PaymentMethod
            handleSelectPaymentMethod={setSelectedPaymentMethod}
            savedCards={savedCards}
            savedBankAccounts={savedBankAccounts}
            setSelectedPaymentCard={setSelectedPaymentCard}
            setSelectedPaymentAccount={setSelectedPaymentAccount}
          />
        );
      case 3:
        if (sum) return "Unknown step";

        if (selectedPaymentMethod === "account") {
          // if (consumersData?.profile?.new_payment_system && selectedPaymentAccount == "" || consumersData?.profile?.new_payment_system && selectedPaymentAccount == "new") {
          //   return (
          //     <ACHIframe 
          //       handleBack={handleBack}
          //       handleShowSuccess={handleShowSuccess}
          //       startDay={startDay}
          //       planId={planInfo.id}
          //       planInfo={planInfo}
          //       // setCard={setCard}
          //       changePaymentMethod={false}
          //       isPlanEstablished={!!debtInfo.loan_id}
          //     />
          //   )
          // } else {
            return (
              <BankAccountPayment
                accountPaymentType={id ? "multi-call" : "multi-web"}
                handleSetAccountPaymentRequest={handleSetAccountPaymentRequest}
                planInfo={planInfo}
                selectedPaymentAccount={selectedPaymentAccount}
              />
            );
          // }
        } else if (selectedPaymentMethod === "card") {
          setupStartDayForMonthlyToday();
          return (
            <CardPayment
              handleBack={handleBack}
              handleShowSuccess={handleShowSuccess}
              startDay={startDay}
              planId={planInfo.id}
              planInfo={planInfo}
              setCard={setCard}
              changePaymentMethod={false}
              isPlanEstablished={!!debtInfo.loan_id}
            />
          );
        }
        break;
      default:
        return "Unknown step";
    }
  }

  // TODO: remove this after refactoring
  const handleCardPayment = async () => {
    let data;
    // let balanceData;
    if (sum) {
      const request = {
        payment_method: "card",
        card_id: selectedPaymentCard,
        debt_id: debtId,
        amount: sum,
        agreement_timestamp: new Date(),
      };
      setLoading(true);
      if (debtInfo.loan_id) {
        if (id) {
          data = await submitExtraPayment({ ...request, client_id: id });
        } else {
          data = await submitExtraPayment(request);
        }
      } else {
        if (id) {
          data = await submitOneTimePayment({ ...request, client_id: id });
        } else {
          data = await submitOneTimePayment(request);
        }
      }

      // balanceData = await getBalance();

      // if (balanceData.debts_info) {
      //   const filtered = balanceData.debts_info.filter(
      //     (debt) => +debt.id === +debtId
      //   );

      //   filtered.length &&
      //     setDebtInfo((oldDebtInfo) => ({
      //       ...oldDebtInfo,
      //       currentBalance: filtered[0].current_balance,
      //     }));
      // }
    } else {
      let request;

      request = {
        card_id: selectedPaymentCard,
        payment_method: "card",
        plan_id: planInfo.id,
        debt_id: debtId,
        start_day: +startDay,
        start_date: moment(paymentDates.start_date).format("YYYY-MM-DD"),
        end_date: moment(paymentDates.finish_date).format("YYYY-MM-DD"),
        agreement_timestamp: new Date(),
      };

      setLoading(true);
      if (id) {
        data = await submitPayment({ ...request, client_id: id });
        setupPlanInfo();
      } else {
        data = await submitPayment(request);
        setupPlanInfo();
      }
    }

    setCard(data);
    setLoading(false);

    if (data.success !== "True") {
      data.message
        ? alert.error(data.message)
        : alert.error("Something went wrong. Try to reload the page.");
      return;
    }

    handleShowSuccess();
  };

  const handlePhysicalPayment = async () => {
    setLoading(true);

    const request = {
      payment_method: "not_provided",
      plan_id: planInfo.id,
      debt_id: debtId,
      start_day: +startDay,
      start_date: moment(paymentDates.start_date).format("YYYY-MM-DD"),
      end_date: moment(paymentDates.finish_date).format("YYYY-MM-DD"),
      agreement_timestamp: new Date(),
    };

    const data = await submitPayment({ ...request, client_id: id });
    setupPlanInfo();

    if (data.success !== "True") {
      data.message
        ? alert.error(data.message)
        : alert.error("Something went wrong. Try to reload the page.");
      return;
    }

    setLoading(false);

    handleShowSuccess();
  };

  const handleNext = async () => {

    setupStartDayForMonthlyToday();

    if (!sum && activeStep === 0) {
      reviewPlanDataLayer();
    }

    if (!sum && activeStep === 1) {
      paymentDateDataLayer();
    }

    if (!sum && activeStep === 2) {
      paymentMethodDataLayer();
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep, skipped)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 2 && selectedPaymentMethod === "physical") {
      handlePhysicalPayment();
      return;
    }

    // account payment Iframe + new TKL check 
    // if (consumersData?.profile?.new_payment_system && selectedPaymentMethod === "account" && selectedPaymentAccount !== "" && selectedPaymentAccount !== "new") {
    //   handleAccountPayment();
    // }

    // Account payment for OTP
    if (sum && activeStep === 2 && selectedPaymentMethod === "account") {
      handleAccountPayment();
      // return;
    }
    
    if (selectedPaymentCard !== "" && selectedPaymentCard !== "new") {
      handleCardPayment();
    } else {
      if (activeStep === 3 && selectedPaymentMethod === "account") {
        // ACH payment
        setLoading(true);
        if (!accountPaymentRequest.account_type) {
          accountPaymentRequest.account_type = "1";
        }
        if (!accountPaymentRequest.authorization) {
          accountPaymentRequest.authorization = "web";
        }
        let accountData;
        if (selectedPaymentAccount === "new") {
          accountData = await addBankAccount({
            ...accountPaymentRequest,
            client_id: id,
          });

          if (accountData.success !== "True") {
            setLoading(false);
            setErrorMessage(accountData.message);
            dispatch(setIsNextButtonDisabled(true));
            return;
          }
        }

        let data;

        if (sum) {
          const request = {
            ...accountPaymentRequest,
            payment_method: "ach",
            card_id: selectedPaymentCard,
            debt_id: debtId,
            amount: sum,
            agreement_timestamp: new Date(),
          };

          if (debtInfo.loan_id) {
            data = await submitExtraPayment(request);
          } else {
            data = await submitOneTimePayment(request);
          }
        } else {
          const request = {
            account_number:
              accountPaymentRequest.account_number ||
              selectedPaymentAccount.AccountNumber,
            name_on_check:
              accountPaymentRequest.name_on_check ||
              selectedPaymentAccount.NameOnCheck,
            routing_number:
              accountPaymentRequest.routing_number ||
              selectedPaymentAccount.RoutingNumber,
            account_type:
              accountPaymentRequest.account_type ||
              selectedPaymentAccount.TypeOfAccount,
            // email: accountPaymentRequest.email,
            bank_name:
              accountPaymentRequest.bank_name ||
              selectedPaymentAccount.BankName,
            card_id: accountData?.account_id || selectedPaymentAccount.Id,
            payment_method: "ach",
            plan_id: planInfo.id,
            debt_id: debtId,
            start_day: +startDay,
            start_date: moment(paymentDates.start_date).format("YYYY-MM-DD"),
            end_date: moment(paymentDates.finish_date).format("YYYY-MM-DD"),
            agreement_timestamp: new Date(),
          };
          setLoading(true);
          data = await submitPayment({ ...request, client_id: id });
          setupPlanInfo();
        }

        if (data.success !== "True") return;
        setAccount(data.bank_account_details);
        setLoading(false);

        handleShowSuccess();
        return;
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setSkipped(newSkipped);
  };

  const handleAccountPayment = async () => {
    setLoading(true);
    // One time payment
    if (sum) {
      let data;
      if (!accountPaymentRequest.account_type) {
        accountPaymentRequest.account_type = "1";
      }
      if (!accountPaymentRequest.authorization) {
        accountPaymentRequest.authorization = "web";
      }
      if (selectedPaymentAccount === "new") {
        data = await addBankAccount({
          ...accountPaymentRequest,
          client_id: id,
        });
        if (data.success === "True") {
          setSelectedPaymentCard(data.account_id);

          submitOtpExtraPaymentACH(accountPaymentRequest, data);
        } else {
          alert.error(data.message);
          setLoading(false);
          setActiveStep((prevActiveStep) => prevActiveStep - 2);
        }

      } else {
        setSelectedPaymentCard(selectedPaymentAccount.Id);

        submitOtpExtraPaymentACH(selectedPaymentAccount);
      }
    } else {

      const request = {
        ...accountPaymentRequest,
        card_id: selectedPaymentAccount,
        payment_method: "ach",
        plan_id: planInfo.id,
        debt_id: debtId,
        start_day: +startDay,
        start_date: moment(paymentDates.start_date).format("YYYY-MM-DD"),
        end_date: moment(paymentDates.finish_date).format("YYYY-MM-DD"),
        agreement_timestamp: new Date(),
      };
  
      setLoading(true);
  
      const data = await submitPayment({ ...request, client_id: id });
      setupPlanInfo();
  
      if (data.success !== "True") {
        alert.error(data.message);
        return;
      }
  
      setAccount(data.bank_account_details);
      setLoading(false);
  
      handleShowSuccess();
    }
  };

  const submitOtpExtraPaymentACH = async (data, addCardInfo) => {   
    let submitData;
    if (id) {
      const requestObjectWithClientID = {
        ...accountPaymentRequest,
        account_number: data.AccountNumber || addCardInfo?.account_number || data.account_number,
        bank_name: data.BankName || addCardInfo?.bank_name || data.bank_name,
        routing_number: data.RoutingNumber || addCardInfo?.routing_number || data.routing_number,
        amount: sum,
        debt_id: debtId,
        client_id: id,
        payment_method: "ach",
        card_id: addCardInfo?.account_id || data.Id || data.account_id,
        agreement_timestamp: new Date(),
      }
      if (debtInfo.loan_id) {
        submitData = await submitExtraPayment(
          requestObjectWithClientID
        );
      } else {
        submitData = await submitOneTimePayment(
          requestObjectWithClientID
        );
      }
    } else {
      const requestObjectWithoutClientID = {
        ...accountPaymentRequest,
        account_number: data.AccountNumber || addCardInfo?.account_number || data.account_number,
        bank_name: data.BankName || addCardInfo?.bank_name || data.bank_name,
        routing_number: data.RoutingNumber || addCardInfo?.routing_number || data.routing_number,
        amount: sum,
        debt_id: debtId,
        card_id: addCardInfo?.account_id || data.Id || data.account_id,
        payment_method: "ach",
        agreement_timestamp: new Date(),
      }
      if (debtInfo.loan_id) {
        submitData = await submitExtraPayment(
          requestObjectWithoutClientID
        );
      } else {
        submitData = await submitOneTimePayment(
          requestObjectWithoutClientID
        );
      }
      let balanceData;
      balanceData = await getBalance();

      const filtered = balanceData.debts_info.filter(
        (debt) => +debt.id === +debtId
      );

      filtered.length &&
        setDebtInfo((oldDebtInfo) => ({
          ...oldDebtInfo,
          currentBalance: filtered[0].current_balance,
        }));
    }

    if (submitData.success === "True") {

      setAccount(submitData.bank_account_details);
      setLoading(false);
      handleShowSuccess();
    } else {
      alert.error(submitData.message);
      setLoading(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 2);

      return;
    }
  }

  const clearingACHForm = () => {
    dispatch(
      setRequest({
        ...accountPaymentRequest,
        debt_id: debtId,
        institution_number: 803,
        account_type: "1",
        account_number: "",
        bank_name: "",
        routing_number: "",
      })
    );
  }

  const handleBack = () => {
    setStartDay(defaultStartDay);
    setIsDateInvalid(false);
    setErrorMessage("");
    clearingACHForm();
    setSelectedPaymentAccount("new");
    setSelectedPaymentMethod("account");
    dispatch(setIsNextButtonDisabled(true));
    if (activeStep === 0) {
      if (id) {
        setLoading(true);
        localStorage.removeItem("paymentSum");
        window.location.replace(`/admin/consumers/consumer/${id}`);
      } else {
        setLoading(true);
        localStorage.removeItem("paymentSum");
        window.location.replace("/");
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const isNextDisabled = () => {
    return (
      isDateInvalid ||
      loading ||
      (activeStep === 1 && isNextButtonDisabled && !sum) 
      ||
      ((activeStep === 3 || (activeStep === 2 && sum)) &&
        selectedPaymentMethod === "card") 
      ||
      (isNextButtonDisabled &&
        activeStep === 3 &&
        !sum &&
        selectedPaymentMethod === "account" &&
        selectedPaymentAccount === "new") 
      ||
      (isNextButtonDisabled &&
        activeStep === 2 &&
        sum &&
        selectedPaymentMethod === "account" &&
        selectedPaymentAccount === "new")
    );
  };

  const nextButtonText = () => {
    if (!!sum && activeStep === 2) return `Pay $${sum}`;
    return "Next";
  };

  return (
    <div className="setup-plan default-container">
      {!sum ? (
        <Helmet>
          <title>
            {showTitle("Setup Plan")}
          </title>
        </Helmet>
      ) : null}
      {isSuccess ? (
        <Success
          debtInfo={debtInfo}
          card={card}
          planInfo={planInfo}
          account={account}
          startDay={startDay}
          paymentType={paymentType}
          isPlanEstablished={!!debtInfo.loan_id}
          isPhysicalPayment={selectedPaymentMethod === "physical"}
          standardClient={debtInfo.display_id}
        />
      ) : (
        <div
          className={
            (activeStep === 3 || (activeStep === 2 && sum)) && selectedPaymentMethod === "card" 
              ? "setup-plan-container setup-plan-container--repay"
              : "setup-plan-container"
          }
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index, skipped)) {
                stepProps.completed = false;
              }

              const showLabels =
                (activeStep !== 3 && !sum) || (activeStep !== 2 && sum);

              return (
                <Step key={label} {...stepProps}>
                  {showLabels && <StepLabel {...labelProps}>{label}</StepLabel>}
                </Step>
              );
            })}
          </Stepper>
          {loading ? (
            <LoadingSpinner middleFixed={true}/>
          ) : (
            <div className="setup-plan__body">{getStepContent(activeStep)}</div>
          )}
          { (selectedPaymentMethod === "card" && activeStep === 3) || 
            (selectedPaymentMethod === "card" && activeStep === 2 && sum !== null) ? 
            (
              null
            ) : (
              <div className="setup-plan__footer">
                <div className="error-message">{errorMessage}</div>
                <CustomButton
                  type="secondary"
                  text="Back"
                  onClickHandler={handleBack}
                  disabled={loading}
                />
                <CustomButton
                  text={nextButtonText()}
                  onClickHandler={handleNext}
                  disabled={isNextDisabled()}
                />
              </div>
            )
          }
          {/* <h4 className="heading heading-body-bold">
            Additional plans may be available. Please call{" "}
            <a href="tel:+18888520054">888-852-0054</a> for more options.
          </h4> */}
        </div>
      )}
    </div>
  );
};
